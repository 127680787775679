import React from "react";
import Slider from "react-slick";

function Testimonial() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <section className="section testimonial-section">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-7">
              <h2 className="heading" data-aos="fade-up">
                People Says
              </h2>
            </div>
          </div>
          <div></div>
          {/* <div className="row"> */}

          <Slider {...settings}>
            <div className="testimonial text-center slider-item">
              <div className="author-image mb-3">
                <img
                  src="/image/psr3.jpeg"
                  alt="Image placeholder"
                  className="rounded-circle mx-auto"
                />
              </div>
              <blockquote>
                <p>
                  &ldquo;• "I recently celebrated my wedding reception at
                  Krishna Pride, and it was the most perfect day. The banquet
                  hall was beautifully decorated, and the staff went above and
                  beyond to make sure everything was taken care of. The food was
                  delicious, and all of our guests raved about how amazing the
                  venue was. Thank you, Krishna Pride, for making our special
                  day truly unforgettable." &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash;  Ravi and Priya S.</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <div className="author-image mb-3">
                <img
                  src="/image/psr1.jpeg"
                  alt="Image placeholder"
                  className="rounded-circle mx-auto"
                />
              </div>
              <blockquote>
                <p>
                  &ldquo;• "I stayed at Krishna Pride with my family, and we
                  were blown away by the level of hospitality we received. The
                  staff were incredibly friendly and welcoming, and they went
                  out of their way to make sure our stay was comfortable and
                  enjoyable. The hotel's location is also very convenient, with
                  plenty of shops and restaurants within walking distance. We
                  would definitely stay here again and would highly recommend it
                  to anyone visiting the city."&rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Ayesha K.</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <div className="author-image mb-3">
                <img
                  src="/image/psr3.jpeg"
                  alt="Image placeholder"
                  className="rounded-circle mx-auto"
                />
              </div>
              <blockquote>
                <p>
                  &ldquo;• • "I recently stayed at Krishna Pride for a business
                  trip, and I was blown away by the level of service and
                  attention to detail. The staff went above and beyond to make
                  sure my stay was comfortable and stress-free. The room was
                  spacious and well-appointed, and the food at the restaurant
                  was some of the best I've ever had. I would highly recommend
                  this hotel to anyone visiting the city." &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash;  John D.</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <div className="author-image mb-3">
                <img
                  src="/image/psr1.jpeg"
                  alt="Image placeholder"
                  className="rounded-circle mx-auto"
                />
              </div>
              <blockquote>
                <p>
                  &ldquo;• • "Krishna Pride is an exceptional hotel that offers
                  a truly luxurious experience. The rooms are beautifully
                  designed and offer all the amenities you could ever need, and
                  the staff are incredibly attentive and helpful. The restaurant
                  serves up delicious food, and the spa and fitness center are
                  top-notch. I would definitely stay here again and would
                  recommend it to anyone looking for a five-star hotel
                  experience."&rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash;  Sarah P.</em>
              </p>
            </div>
            {/* <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; Jean Smith</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                    leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div> */}
          </Slider>

          {/* <div className="js-carousel-2 owl-carousel mb-5" data-aos="fade-up" data-aos-delay="200">
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; Jean Smith</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                    leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; Jean Smith</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                    leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
            </div> */}

          {/* </div> */}
        </div>
      </section>
    </div>
  );
}

export default Testimonial;
