import React from "react";

function About() {
  return (
    <div>
      <section className="py-5 bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-12 col-lg-7 ml-auto order-lg-2 position-relative mb-5"
              data-aos="fade-up"
            >
              <figure className="img-absolute">
                <img
                  src="images/xfood-1.jpg.pagespeed.ic.Lom9I4U3Dw.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </figure>
              <img
                src="image/2.jpeg"
                alt="Image"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-12 col-lg-4 order-lg-1" data-aos="fade-up">
              <h2 className="heading">Welcome!</h2>
              <p className="mb-4">
                Looking for a luxurious stay in the heart of the city? Look no
                further than Krishna Pride, a premium hotel that offers
                world-class amenities and exceptional service. Located in the
                bustling city center, Krishna Pride is a top-rated hotel that
                caters to both business and leisure travelers. With its
                exquisite interiors, modern facilities, and impeccable service,
                Krishna Pride has become a favorite among tourists and locals
                alike. The hotel offers a range of luxurious rooms and suites
                that are designed to cater to the needs of discerning travelers.
                Each room is tastefully furnished with modern amenities,
                including air conditioning, flat-screen TVs, and high-speed
                Wi-Fi. The rooms also offer stunning views of the city, making
                it the perfect place to relax after a long day of sightseeing or
                business meetings.
              </p>
              <p>
                <a href="image/WhatsApp Video 2022-05-17 at 2.28.37 PM.mp4"   data-fancybox className="btn btn-primary text-white py-2 mr-3">
                See video
                </a>{" "}
                {/* <span className="mr-3 font-family-serif">
                  <em>or</em>
                </span>{" "}
                <a
                  href="image/WhatsApp Video 2022-05-17 at 2.28.37 PM.mp4"
                  data-fancybox
                  className="text-uppercase letter-spacing-1"
                >
                  See video
                </a> */}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
