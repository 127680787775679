import React from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "../AdminComponent/AdminLogin";
import AdminPage from "../AdminComponent/AdminPage";
import ContectEnquiry from "../AdminComponent/ContactEnquiry";
import Enquiry from "../AdminComponent/Enquiry";
import AdminEvents from "../AdminComponent/Events";
import AdminGallery from "../AdminComponent/Gallery";
import Menu from "../AdminComponent/Menu";
import Rooms from "../AdminComponent/Rooms";
import Abouts from "../Component/About/Abouts";
import BookRoom from "../Component/BookRoom/BookRoom";
import Contact from "../Component/Contact/Contact";
import Events from "../Component/Events/Events";
import Gallery from "../Component/Gallery/Gallery";
// import AuthAdmin from './AuthAdmin';
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import Reservation from "../Component/Reservation/Reservation";
import AuthAdmin from "./AuthAdmin";
import AdminCategory from "../AdminComponent/Category";
// import Account from '../Component/AccountDetails/Account';

function AppRoutes() {
  return (
    // < Switch >
    //   < Route exact path="/" component={UserTable} />
    //   < Route exact path="/login" component={Login} />
    // </Switch>

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/abouts" element={<Abouts />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/login" element={<Login />} />
        <Route exact path="/book-room" element={<BookRoom />} />

        {/* admin  */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin" element={<AuthAdmin cmp={AdminPage} />} />
        <Route path="/admin/rooms" element={<AuthAdmin cmp={Rooms} />} />
        <Route path="/admin/menu" element={<AuthAdmin cmp={Menu} />} />
        <Route path="/admin/enquiry" element={<AuthAdmin cmp={Enquiry} />} />
        <Route path="/admin/category" element={<AuthAdmin cmp={AdminCategory} />} />
        <Route
          path="/admin/gallery"
          element={<AuthAdmin cmp={AdminGallery} />}
        />
        <Route path="/admin/events" element={<AuthAdmin cmp={AdminEvents} />} />
        <Route
          path="/admin/contact-enqury"
          element={<AuthAdmin cmp={ContectEnquiry} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
