import React, { useState } from "react";
import { db } from "../../Firebase";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import dateFormat from "dateformat";

import { BiDownArrowAlt, BiCalendarAlt } from "react-icons/bi";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Reservations from "../Home/Reservations";
import Testimonial from "../Home/Testimonial";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

function Reservation() {
  const [mynumber, setmynumber] = useState("");
  const [details, setDetails] = useState("");
  const [person, setperson] = useState("1");
  const [childrens, setchildrens] = useState("0");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const hendledetailsAdd = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const userMainPage = async () => {
    if (startDate == null) {
      toast.error("Please Select Check In Date", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("id01").style.display = "none";
    } else if (endDate === null) {
      toast.error("Please Select Check Out Date", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("id01").style.display = "none";
    } else {
      if (localStorage.getItem("user_id")) {
        const uid = localStorage.getItem("user_id");

        db.collection("userDetails")
          .where("uid", "==", uid)
          .get()
          .then((snapshot) => {
            const oderdetails = snapshot.docs.map((e) => {
              const data = e.data();
              data.id = e.id;
              return data;
            });

            const dataafinal = oderdetails[0];

            const std = dateFormat(startDate, "dd-mm-yyyy");
            const end = dateFormat(endDate, "dd-mm-yyyy");
            // console.log("sfdsf");

      const tokenDoc =  db.collection("admin").doc("token").get();
      if (tokenDoc.exists) {
        const token = tokenDoc.data().token;

        const baseUrl =
          "https://krishnapride.com/notify.php?token=" + token +
          "&name=" + dataafinal?.name +
          "&mobile=" + dataafinal?.number;


            // const baseUrl =
            //   "https://wa.betablaster.in/api/send.php?number=918619087233&type=text&message=Clint%20Number%20%3D%20" +
            //   dataafinal?.number +
            //   "%0AClint%20Name%20%3D%20" +
            //   dataafinal?.name +
            //   "%0AAdults%20%3D%20" +
            //   person +
            //   "%0AChildren%20%3D%20" +
            //   childrens +
            //   "%0ACheck%20in%20Date%20%3D%20" +
            //   std +
            //   "%0ACheck%20out%20Date%20%3D%20" +
            //   end +
            //   "&instance_id=63170A452EA13&access_token=e19e6ecbb0ec4719405f2c19d8bf96e2";
            var myHeaderss = new Headers();
            myHeaderss.append("Content-Type", "text/plain");
            myHeaderss.append("Access-Control-Allow-Origin", "*");
            myHeaderss.append("Access-Control-Allow-Credentials", "true");
            var raw = JSON.stringify({});
            var requestOptionss = {
              method: "get",
              mode: "no-cors",
              headers: myHeaderss,
              // body: raw,
              redirect: "follow",
            };
            fetch(baseUrl, requestOptionss)
              .then((response) => response.json())
              .then((result) => {
                console.log(result);
                if (result === "Successfully") {
                } else {
                }
              });

            const dones = db.collection("userEnquiry").add({
              name: dataafinal?.name,
              checkInDate: startDate,
              checkOutDate: endDate,
              person: person,
              childrens: childrens,
              phoneNumber: dataafinal?.number,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            // document.getElementById('id01').style.display = 'none'
            // toast.success("Your Enquiry Submited")
            setendDate(null);
            setStartDate(null);
            setmynumber("91");
            setperson("1");
            setDetails({ name: "" });
            setchildrens("0");

            toast.success("Your Enquiry Submited", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            }else{
              console.error("Token document does not exist.");
            }
            
          });

      } else {
        if (!details?.name) {
          toast.error("Please Enter your name", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (mynumber?.length < 10) {
          toast.error("Please Enter valid number", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const std = dateFormat(startDate, "dd-mm-yyyy");
          const end = dateFormat(endDate, "dd-mm-yyyy");
          // console.log("sfdsf");


          const tokenDoc = await db.collection("admin").doc("token").get();

          if (tokenDoc.exists) {
            const token = tokenDoc.data().token;
    
            const baseUrl =
              "https://krishnapride.com/notify.php?token=" + token +
              "&name=" + details?.name +
              "&mobile=" + mynumber;
    
          // const baseUrl =
          //   "https://wa.betablaster.in/api/send.php?number=918619087233&type=text&message=Clint%20Number%20%3D%20" +
          //   mynumber +
          //   "%0AClint%20Name%20%3D%20" +
          //   details?.name +
          //   "%0AAdults%20%3D%20" +
          //   person +
          //   "%0AChildren%20%3D%20" +
          //   childrens +
          //   "%0ACheck%20in%20Date%20%3D%20" +
          //   std +
          //   "%0ACheck%20out%20Date%20%3D%20" +
          //   end +
          //   "&instance_id=63170A452EA13&access_token=e19e6ecbb0ec4719405f2c19d8bf96e2";
          var myHeaderss = new Headers();
          myHeaderss.append("Content-Type", "text/plain");
          myHeaderss.append("Access-Control-Allow-Origin", "*");
          myHeaderss.append("Access-Control-Allow-Credentials", "true");
          var raw = JSON.stringify({});
          var requestOptionss = {
            method: "get",
            mode: "no-cors",
            headers: myHeaderss,
            // body: raw,
            redirect: "follow",
          };
          fetch(baseUrl, requestOptionss)
            .then((response) => response.json())
            .then((result) => {
              console.log(result);
              if (result === "Successfully") {
              } else {
              }
            });

          const dones = await db.collection("userEnquiry").add({
            name: details?.name,
            checkInDate: startDate,
            checkOutDate: endDate,
            person: person,
            childrens: childrens,
            phoneNumber: "+" + mynumber,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });

          setendDate(null);
          setStartDate(null);
          setmynumber("91");
          setperson("1");
          setDetails({ name: "" });
          setchildrens("0");

          toast.success("Your Enquiry Submited", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }else{
          console.error("Token document does not exist.");
        }
        }
      }
    }
  };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <section className="section contact-section bg-fade" style={{marginTop:"96px"}} id="next">
        <div className="container">
          <h1 className="contact-heading">Reservation Form</h1>
          <div className="row">
            <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
              <form
                action="#"
                method="post"
                className="bg-white p-md-5 p-4 mb-5 border"
              >
                {localStorage.getItem("user_id") ? (
                  " "
                ) : (
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label className="text-black font-weight-bold" for="name">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={details?.name}
                        name="name"
                        onChange={(e) => {
                          hendledetailsAdd(e);
                        }}
                      />
                    </div>
                    {/* <div className="col-md-6 form-group">
                <label className="text-black font-weight-bold" for="phone">Phone</label>
                <input type="text" id="phone" className="form-control " />
              </div> */}
                  </div>
                )}

                {localStorage.getItem("user_id") ? (
                  " "
                ) : (
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label
                        className="text-black font-weight-bold"
                        for="email"
                      >
                        Phone
                      </label>
                      <PhoneInput
                        country={"in"}
                        value={mynumber}
                        onChange={setmynumber}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label
                      className="text-black font-weight-bold"
                      for="checkin_date"
                    >
                      Date Check In
                    </label>
                    <div className="field-icon-wrap">
                      <div className="icon">
                        {" "}
                        <BiCalendarAlt />
                      </div>
                      {/*    <input type="date" id="checkin_date" className="form-control" placeholder="Select date" /> */}
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // showTimeSelect
                        // timeFormat="HH:mm:ss"
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Select date"
                        // dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form-group">
                    <label
                      className="text-black font-weight-bold"
                      for="checkout_date"
                    >
                      Date Check Out
                    </label>
                    <div className="field-icon-wrap">
                      <div className="icon">
                        <BiCalendarAlt />
                        {/* <span className="icon-calendar">
                         </span> */}
                      </div>

                      {/* <input type="date" id="checkout_date" className="form-control" /> */}
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setendDate(date)}
                        // showTimeSelect
                        // timeFormat="HH:mm:ss"
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Select date"
                        // dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label for="adults" className="font-weight-bold text-black">
                      Adults
                    </label>
                    {/* <div className="field-icon-wrap">
                  <div className="icon"><span className="ion-ios-arrow-down"></span></div>
                  <select name="" id="adults" className="form-control">
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="">4+</option>
                  </select>
                </div> */}
                    <div className="field-icon-wrap">
                      <div className="icon">
                        <BiDownArrowAlt />
                        {/* <span className="ion-ios-arrow-down"></span> */}
                      </div>

                      <select
                        name=""
                        id="adults"
                        value={person}
                        onChange={(e) => {
                          setperson(e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4+">4+</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 form-group">
                    <label
                      for="children"
                      className="font-weight-bold text-black"
                    >
                      Children
                    </label>
                    {/* <div className="field-icon-wrap">
                  <div className="icon"><span className="ion-ios-arrow-down"></span></div>
                  <select name="" id="children" className="form-control">
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="">4+</option>
                  </select>
                </div> */}
                    <div className="field-icon-wrap">
                      <div className="icon">
                        <BiDownArrowAlt />
                        {/* <span className="ion-ios-arrow-down"></span> */}
                      </div>
                      <select
                        name=""
                        id="children"
                        value={childrens}
                        onChange={(e) => {
                          setchildrens(e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4+">4+</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-4">
              <div className="col-md-12 form-group">
                <label className="text-black font-weight-bold" for="message">Notes</label>
                <textarea name="message" id="message" className="form-control " cols="30" rows="8"></textarea>
              </div>
            </div> */}
                <div className="row">
                  {/* <div className="col-md-6 form-group">
                    <input type="submit" value="Reserve Now" className="btn btn-primary text-white py-3 px-5 font-weight-bold" />
                  </div> */}
                  <div className="enSubBtn">
                    <button
                      type="button"
                      className={"btn btn-primary  enSub"}
                      onClick={(e) => {
                        userMainPage(e);
                      }}
                    >
                      Reserve Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5" data-aos="fade-up" data-aos-delay="200">
              <div className="row">
                <div className="col-md-10 ml-auto contact-info">
                  <p>
                    <span className="d-block">Address:</span>{" "}
                    <span className="text-black">
                      {" "}
                      Nivik hospital, Udb Indus, Opposite Nikki road lines , 29
                      uday nagar b, Jaipur, Rajasthan 302020
                    </span>
                  </p>
                  <p>
                    <span className="d-block">Phone:</span>

                    <span className="text-black">+918005501767</span>
                    <br></br>
                    <span className="text-black">+919928992889</span>
                  </p>
                  <p>
                    <span className="d-block">Email:</span>{" "}
                    <span className="text-black">
                      {" "}
                      <a
                        href="mailto:Hotelkrishnapride@gmail.com"
                        className="__cf_email__"
                      >
                        Hotelkrishnapride@gmail.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonial />
      <Reservations />
      <Footer />
    </div>
  );
}

export default Reservation;
