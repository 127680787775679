import React from 'react'
import ScrollToTop from '../../ScrollToTop/ScrollToTop'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import About from '../Home/About'
import Photos from '../Home/Photos'
import Reservations from '../Home/Reservations'

function Abouts() {
    return (
        <div>
            <ScrollToTop/>
            <Header/>
            <section className="site-hero inner-page overlay"
                style={{ backgroundImage: "url(/image/1.jpeg)" }} data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row site-hero-inner justify-content-center align-items-center">
                        <div className="col-md-10 text-center" data-aos="fade">
                            <h1 className="heading about-custom-heading mb-3">About Us</h1>
                            <ul className="custom-breadcrumbs mb-4">
                                <li><a href="index.html">Home</a></li>
                                <li>&bullet;</li>
                                <li>About</li>
                                <div className="cta-2">
                                    <a href="image/WhatsApp Video 2022-05-17 at 2.28.32 PM.mp4" data-fancybox title="Watch our video"><i
                                        className="fa-solid fa-play"></i></a>
                                </div>
                                <div className="our-video text-white mt-2"> <a href="image/WhatsApp Video 2022-05-17 at 2.28.32 PM.mp4" data-fancybox
                                   >Watch Our Video</a>
                                </div>
                            </ul>



                        </div>
                    </div>
                </div >
                <a className="mouse smoothscroll" href="#next">
                    <div className="mouse-icon">
                        <span className="mouse-wheel"></span>
                    </div>
                </a>
            </section >


            {/* <section className="py-5 bg-light" id="next">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-7 ml-auto order-lg-2 position-relative mb-5" data-aos="fade-up">
                            <figure className="img-absolute">
                                <img src="images/xfood-1.jpg.pagespeed.ic.Lom9I4U3Dw.jpg" alt="Free Website Template by Templateux"
                                    className="img-fluid" />
                            </figure>
                            <img src="image/events/22.png" alt="Image" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-12 col-lg-4 order-lg-1" data-aos="fade-up">
                            <h2 className="heading">Welcome!</h2>
                            <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there
                                live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large
                                language ocean.</p>
                            <p><a href="#" className="btn btn-primary text-white py-2 mr-3">Learn More</a> <span
                                className="mr-3 font-family-serif"><em>or</em></span> <a href="https://vimeo.com/channels/staffpicks/93951774"
                                    data-fancybox className="text-uppercase letter-spacing-1">See video</a></p>
                        </div>
                    </div>
                </div>
            </section> */}
            <About/>


            {/* <div className="container section">
                <div className="row justify-content-center text-center mb-5">
                    <div className="col-md-7 mb-5">
                        <h2 className="heading" data-aos="fade-up">Leadership</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="block-2">
                            <div className="flipper">
                                <div className="front" style={{ backgroundImage: "url(images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg)" }}>
                                    <div className="box">
                                        <h2>Will Peters</h2>
                                        <p>President</p>
                                    </div>
                                </div>
                                <div className="back">

                                    <blockquote>
                                        <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                                            unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                                            leave for the far World of Grammar.&rdquo;</p>
                                    </blockquote>
                                    <div className="author d-flex">
                                        <div className="image mr-3 align-self-center">
                                            <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="" />
                                        </div>
                                        <div className="name align-self-center">Will Peters <span className="position">President</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="block-2">
                            <div className="flipper">
                                <div className="front" style={{ backgroundImage: "url(images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg)" }}>
                                    <div className="box">
                                        <h2>Jane Williams</h2>
                                        <p>Business Manager</p>
                                    </div>
                                </div>
                                <div className="back">

                                    <blockquote>
                                        <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                                            unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                                            leave for the far World of Grammar.&rdquo;</p>
                                    </blockquote>
                                    <div className="author d-flex">
                                        <div className="image mr-3 align-self-center">
                                            <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="" />
                                        </div>
                                        <div className="name align-self-center">Jane Williams <span className="position">Business Manager</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
                        <div className="block-2">
                            <div className="flipper">
                                <div className="front" style={{ backgroundImage: "url(images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg)" }}>
                                    <div className="box">
                                        <h2>Jeffrey Neddery</h2>
                                        <p>Marketing Director</p>
                                    </div>
                                </div>
                                <div className="back">

                                    <blockquote>
                                        <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                                            unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                                            leave for the far World of Grammar.&rdquo;</p>
                                    </blockquote>
                                    <div className="author d-flex">
                                        <div className="image mr-3 align-self-center">
                                            <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="" />
                                        </div>
                                        <div className="name align-self-center">Jeffrey Neddery <span className="position">Marketing Director</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <section className="section slider-section bg-light">
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-md-7">
                            <h2 className="heading" data-aos="fade-up">Photos</h2>
                            <p data-aos="fade-up" data-aos-delay="100">Far far away, behind the word mountains, far from the countries
                                Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the
                                coast of the Semantics, a large language ocean.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="home-slider major-caousel owl-carousel mb-5" data-aos="fade-up" data-aos-delay="200">
                                <div className="slider-item">
                                    <a href="images/slider-1.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-1.jpg.pagespeed.ic.F0cBwEFU4x.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                                <div className="slider-item">
                                    <a href="images/slider-2.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-2.jpg.pagespeed.ic.o2H9sSMu12.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                                <div className="slider-item">
                                    <a href="images/slider-3.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-3.jpg.pagespeed.ic.bvn5BJWMtN.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                                <div className="slider-item">
                                    <a href="images/slider-4.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-4.jpg.pagespeed.ic.yoBNrJKhJg.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                                <div className="slider-item">
                                    <a href="images/slider-5.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-5.jpg.pagespeed.ic.C_8IFSeIVi.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                                <div className="slider-item">
                                    <a href="images/slider-6.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-6.jpg.pagespeed.ic.K4kKqlQXKc.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                                <div className="slider-item">
                                    <a href="images/slider-7.jpg" data-fancybox="images" data-caption="Caption for this image"><img
                                        src="images/xslider-7.jpg.pagespeed.ic.F-tkFolb72.jpg" alt="Image placeholder" className="img-fluid" /></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}



            {/* <Photos/> */}
{/* 
            <div className="section">
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-md-7 mb-5">
                            <h2 className="heading" data-aos="fade">History</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="timeline-item" date-is='2019' data-aos="fade">
                                <h3>More Branches Worldwide</h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                                    blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language
                                    ocean.</p>
                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a
                                    paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                            </div>
                            <div className="timeline-item" date-is='2011' data-aos="fade">
                                <h3>Company Full Blast</h3>
                                <p>
                                    Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                                    blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language
                                    ocean.
                                </p>
                            </div>
                            <div className="timeline-item" date-is='2008' data-aos="fade">
                                <h3>The Birth of the Company</h3>
                                <p>
                                    Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                                    blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language
                                    ocean.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <Reservations />
            <Footer/>
        </div >
    )
}

export default Abouts