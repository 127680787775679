import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { db } from '../Firebase';





function Deshboard() {





    const [enquiy, setenquiy] = useState([])
    const [conenquiy, setconenquiy] = useState([])
    const [reviews, setreviews] = useState([])
    const [rooms, setrooms] = useState([])
    const [gallry, setgallry] = useState([])
    const [category, setcategory] = useState([])




    useEffect(() => {
        const unSub =  db
            .collection('userEnquiry').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
                const oderdetails = snapshot.docs.map((e) => {
                    const data = e.data()
                    data.id = e.id
                    return data
                });
                setenquiy(oderdetails)
            })
            return () => {
                unSub()
            }
    }, [])



    useEffect(() => {
        const unSub = db.collection('contectEnquiry').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setconenquiy(oderdetails)
        })
        return () => {
            unSub()
        }


    }, [])

    useEffect(() => {
        const unSub =  db.collection('userReview').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setreviews(oderdetails)
        })


        return () => {
            unSub()
        }
    }, [])

    useEffect(() => {
   const unSub = db.collection('roomDetails').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setrooms(oderdetails)
        })
        return () => {
            unSub()
        }
    }, [])








    useEffect(() => {
        const unsub =  db.collection('gallery').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
              const oderdetails = snapshot.docs.map((e) => {
                  const data = e.data()
                  data.id = e.id
                  return data
              });
              setgallry(oderdetails)
          })
  
          return () =>{
              unsub()
          }
      }, [])


      useEffect(() => {
        const unsub =  db.collection('category').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
              const oderdetails = snapshot.docs.map((e) => {
                  const data = e.data()
                  data.id = e.id
                  return data
              });
              setcategory(oderdetails)
          })
  
          return () =>{
              unsub()
          }
      }, [])



    return (

        <div className='dashboardContaint'>
            <div className='container'>


                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <div className='dashboardCard' >
                            <Link to={"/admin/enquiry"}>
                                <h5>Enquiry</h5>
                                <p>{enquiy?.length}</p>
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <div className='dashboardCard'>
                            <Link to={"/admin/reviews"}>
                                <h5>Reviews</h5>
                                <p>{reviews?.length}</p>
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <div className='dashboardCard'>
                            <Link to={"/admin/rooms"}>
                                <h5>Rooms</h5>
                                <p>{rooms?.length}</p>
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <div className='dashboardCard'>
                            <Link to={"/admin/contact-enquiry"}>
                                <h5>Contact Enquiry</h5>
                                <p>{conenquiy?.length}</p>
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <div className='dashboardCard'>
                            <Link to={"/admin/gallery"}>
                                <h5>Gallery</h5>
                                <p>{gallry?.length}</p>
                            </Link>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <div className='dashboardCard'>
                            <Link to={"/admin/category"}>
                                <h5>Category</h5>
                                <p>{category?.length}</p>
                            </Link>
                        </div>
                    </div>
                    {/* <div className='col-12 col-sm-6'>
                    <div className='dashboardCard'>
                        <h5>Enquiry</h5>
                        <p>10</p>
                    </div>
                </div> */}

                </div>


            </div>
        </div>
    )
}

export default Deshboard