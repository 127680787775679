import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function Header() {
    const [opne, setopne] = useState("0")
    const location = useLocation();

    const hendleOpne = ((e) => {
        if (opne == "0") {
            setopne("1")
        } else {
            setopne("0")
        }
    })


    const { pathname } = location;
    const splitLocation = pathname.split("/");

    // cons





    return (
        <div data-aos-easing="ease" data-aos-duration="1000" data-aos-delay="0" data-new-gr-c-s-check-loaded="14.1062.0" data-gr-ext-installed className={opne == "1" ? "menu-open" : ""}>
            <header className="site-header js-site-header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-4 col-lg-4 site-logo" data-aos="fade">

                            <Link to={"/"} ><img src="/image/555.png" alt="image" /></Link>
                            {/* <a href="index.html"></a> */}
                        </div>
                        <div className="col-8 col-lg-8">
                        {/* <div className='contactno'>
                        
                        <p>+918005501767 </p>
                        <p>+919928992889</p>
                        </div> */}
                        <div className='contactno'>
                                <div className='row'>
                                    <div className='col-12 col-lg-6'>
                                        <p>+918005501767 </p>

                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        <p>+919928992889</p>

                                    </div>
                                </div>
                            </div>
                            <div className={opne == "1" ? "site-menu-toggle js-site-menu-toggle aos-init aos-animate open" : "site-menu-toggle js-site-menu-toggle"} data-aos="fade" onClick={() => { hendleOpne() }}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <div className="site-navbar js-site-navbar">
                                <nav role="navigation">
                                    <div className="container">
                                        <div className="row full-height align-items-center">
                                            <div className="col-md-6 mx-auto">
                                                <ul className="list-unstyled menu">
                                                    <li className={splitLocation[1] == "" ? "active" : ""}>
                                                        {/* <a href="index.html">Home</a> */}

                                                        <Link to={"/"}>Home</Link>

                                                    </li>
                                                    {/* <li>
                                                       
                                                        <Link to={"/"}>Rooms</Link>

                                                    </li> */}
                                                    <li className={splitLocation[1] == "abouts" ? "active" : ""}>
                                                        {/* <a href="about.html">About</a> */}
                                                        <Link to={"/abouts"}>About</Link>
                                                    </li>
                                                    <li className={splitLocation[1] == "gallery" ? "active" : ""}>
                                                        {/* <a href="events.html">Events</a> */}
                                                        <Link to={"/gallery"}>Gallery</Link>
                                                    </li>
                                                    <li className={splitLocation[1] == "events" ? "active" : ""}>
                                                        {/* <a href="events.html">Events</a> */}
                                                        <Link to={"/events"}>Events</Link>
                                                    </li>
                                                    <li className={splitLocation[1] == "contact" ? "active" : ""}>
                                                        {/* <a href="contact.html">Contact</a> */}
                                                        <Link to={"/contact"}>Contact</Link>
                                                    </li>
                                                    <li className={splitLocation[1] == "reservation" ? "active" : ""}>
                                                        {/* <a href="reservation.html">Reservation</a> */}
                                                        <Link to={"/reservation"}>Reservation</Link>
                                                    </li>
                                                    <li>
                                                        {/* <a href="reservation.html">Reservation</a> */}
                                                        <Link to={"/login"}>Login</Link>
                                                    </li>




                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header