

import React, { useState, useEffect, useMemo, useRef } from "react";
// import { Link, useHistory } from "react-router-dom";
// import dateFormat from "dateformat";
// import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../Firebase";
import { toast } from "react-toastify";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { TableHeader, Pagination, Search } from "../Table/DataTable";
import AdminBackNav from "./AdminBackNav";
import { Modal, Button } from 'react-bootstrap';
import { MdDelete, Md1KPlus } from "react-icons/md";
import { ImPlus } from 'react-icons/im';
import { HiLockClosed, HiPlusCircle, HiOutlineEye } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Comment, EditOutlined } from "@mui/icons-material";

function AdminCategory() {

    const ref = useRef();
    const [RoomDetails, setRoomDetails] = useState({});
    const [deleteCategory, setdeleteCategory] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10000000000000000);
    const [comments, setComments] = useState([]);
    const [file, setFile] = useState(null);
    const [photoIndex, setphotoIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);
    const imageviews = ((e) => {
        setisOpen(true)
        setphotoIndex(e)

    })


    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setdeleteCategory(e)
        setShow(true)
    };

    const hendlesetRoomDetailsAdd = (e) => {
        const { name, value } = e.target;
        setRoomDetails({ ...RoomDetails, [name]: value });
    };
    const handleImage = (event) => {

        setFile(event.target.files[0])
    };


    // async function sendMessagesss(e) {
    //     e.preventDefault();

    //     if (file !== null) {
    //         const ref = storage.ref(`/images/gallery/${file.name}`);
    //         const uploadTask = ref.put(file);
    //         const oderDone = uploadTask.on(
    //             "state_changed",
    //             console.log,
    //             console.error,
    //             () => {
    //                 ref.getDownloadURL().then((url) => {
    //                     setFile(null);
    //                     const abc = db.collection("category").add({
    //                         image: url,
    //                         title: RoomDetails?.title,
    //                         description: RoomDetails?.description,

    //                         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //                     });

    //                     toast.success("successfully Add")
    //                     reset()
    //                 });
    //             }
    //         );

    //     } else {
    //     }
    // }
    async function sendMessagesss(e) {
        e.preventDefault();
    
        if (file !== null) {
            // ... (your existing code for uploading with an image)
    
        } else {
            // If no file is selected, add data to Firestore collection without an image
            const abc = db.collection("category").add({
                title: RoomDetails?.title,
                description: RoomDetails?.description,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
    
            // Show success toast
            toast.success("Successfully added");
            reset(); // Assuming reset is a function to reset the form
        }
    }
    
    const reset = () => {
        // Reset the form state
        setRoomDetails({
            title: "",
            description: "",
            // Add other fields as needed
        });
    
        // Assuming ref is a reference to the form element
        const formElement = ref.current;
    
        // Reset the form using the reset method
        formElement.reset();
    };
    
    

    

    useEffect(() => {
        const unsub = db.collection('category').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setComments(oderdetails)
        })

        return () => {
            unsub()
        }
    }, [])

    const hendledelete = (async (e) => {
        setShow(false)
        const res = await db.collection('category').doc(deleteCategory).delete();
        toast.success("deleted ")

    })


    const hendleitemsPerPage = (e) => {
        setitemsPerPage(parseInt(e.target.value));
    };

    // const hendlesetRoomDetailsAdd = (e) => {
    //     const { name, value } = e.target;
    //     setRoomDetails({ ...RoomDetails, [name]: value });
    // };

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const headers = [
        // { name: "No", field: "id", sortable: true },
        // { name: "Image", field: "image", sortable: false },
        // { name: "Number", field: "number", sortable: false },
        { name: "Title", field: "title", sortable: false },
        { name: "Description", field: "description", sortable: false },
        // { name: "area", field: "area", sortable: false },
        // { name: "roomtype", field: "roomtype", sortable: false },
        // { name: "aboutRoom", field: "aboutRoom", sortable: false },
        // { name: "Price", field: "Price", sortable: false },
        { name: "Action", field: "action", sortable: false },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.email.toLowerCase().includes(search.toLowerCase())
            );
        }

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * itemsPerPage,
            (currentPage - 1) * itemsPerPage + itemsPerPage
        );
    }, [comments, currentPage, search, sorting, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected) % (comments.length / 2);
        setCurrentPage(newOffset + 1)
        setItemOffset(newOffset * itemsPerPage);

    };



    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(comments.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, comments]);


    const editUser = (val, rowData) => {
        if (val === 'add') {
            document.getElementById('editDiv').style.display = 'block';
            document.getElementById('roomsShow').style.display = 'none';
        }
        if (val === 'cencel') {
            document.getElementById('editDiv').style.display = 'none';
            document.getElementById('roomsShow').style.display = 'block';
        }
    }


    const [roomDetailsAbout, setRoomDetailsAbout] = useState({})
    const [roomId, setroomId] = useState()

    const roomDetailsView = (val, rowData) => {

        // console.log(rowData);
        if (val === 'show') {

     
            db.collection('roomDetails').doc(rowData).get().then(snapshot => {


                setRoomDetailsAbout(snapshot?.data());
            });



            document.getElementById('roomView').style.display = 'block';
            document.getElementById('roomsShow').style.display = 'none';


        }
        if (val === 'cencel') {
            document.getElementById('roomView').style.display = 'none';
            document.getElementById('roomsShow').style.display = 'block';
        }
    }



    // image

    const [srcImg, setSrcImg] = useState(null);
    const [userFile, setuserFile] = useState(null);
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 9 / 6 });
    const [result, setResult] = useState(null);

    const handleImage1 = async (event) => {
        setuserFile(event.target.files[0])
        setSrcImg(URL.createObjectURL(event.target.files[0]));
        setShow1(true)
    };



    const handleClose1 = () => setShow1(false);

    const handleShow1 = (e) => {
        setdeleteCategory(e)
        setShow(true)
    };

  

    const getCroppedImg = async (e) => {
        e.preventDefault();
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );


            const base64Image = canvas.toDataURL("image/jpeg", 1)
            // setResult(base64Image);



            var arr = await canvas.toDataURL("image/jpeg", 1).split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            const userImg = new File([u8arr], userFile?.name, { type: mime });

            setResult(userImg);
            setFile(userImg)
            setSrcImg(null)
            setShow1(false)
        } catch (e) {
            console.log("crop the image");
        }
    };


    const [selectedComment, setSelectedComment] = useState(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [editmoelOpen, seteditmoelOpen] = useState(false);
    const [editedData, setEditedData] = useState({
      title: '',
      description: '',
    });
  console.log(selectedComment)
    const handleeditShow = (comment) => {
        // const selectedCommentData = commentsData.find(comment => comment.id === commentId);
    
        // Set the selected comment to the state
        setSelectedComment(comment);
      seteditmoelOpen(true);
      console.log(comment)
    };
  
    const handleeditClose = () => {
      seteditmoelOpen(false);
    };
  
    const hendlesetedit = (e) => {
        const { name, value } = e.target;
        setSelectedComment({ ...selectedComment,[name]: value }
     );
    };
    // ... (your existing code)

const handleeditsubmit = async () => {
    try {
      // Update the document in the 'category' collection with the edited data
      await db.collection('category').doc(selectedComment.id).update({
        title: selectedComment.title,
        description: selectedComment.description,
        // Add other fields as needed
      });
  
      // Show success toast
      toast.success("Successfully updated");
  
      // Reset the form and close the modal
      seteditmoelOpen(false);
      setSelectedComment({
        title: '',
        description: '',
        // Add other fields as needed
      });
    } catch (error) {
      console.error("Error updating document: ", error);
      // Handle the error (show an error toast, etc.)
    }
  };
  

  

  







    return (
        <div>
            <AdminBackNav />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item active">
                                <a> Food Category</a>
                            </li>
                        </ol>
                    </div>

                    <div className="row" id="roomsShow">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header roomT">
                                    <h4 className="card-title">Food Category</h4>

                                    <div className="addPlus">
                                        <a

                                            className="login_button newQue"

                                            onClick={(e) => { editUser("add") }}
                                        >
                                            {/* Add */}

                                            {/* <HiPlusCircle /> */}
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div
                                        id="example3_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        {/* <div
                                            className="dataTables_length category"
                                            id="example3_length"
                                        >
                                            <label>
                                                Show{" "}
                                                <div className="dropdown category">
                                                    <select
                                                        name="example3_length"
                                                        aria-controls="example3"
                                                        className=""
                                                        tabIndex="-98"
                                                        onChange={(e) => {
                                                            hendleitemsPerPage(e);
                                                        }}
                                                    >
                                                        <option value={"10"}>10</option>
                                                        <option value={"25"}>25</option>
                                                        <option value={"50"}>50</option>
                                                        <option value={"100"}>100</option>
                                                    </select>
                                                </div>{" "}
                                                entries
                                            </label>
                                        </div> */}
                                        {/* <div
                                            id="example3_filter"
                                            className="dataTables_filter category"
                                        >


                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />

                                        </div> */}

                                        <div className="row w-100 tableResponsiv">
                                            <div className="col mb-3 col-12 text-center">
                                                <div className="row">
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6 d-flex flex-row-reverse">

                                                    </div>
                                                </div>

                                                <table className="table table-striped">
                                                    <TableHeader
                                                        headers={headers}
                                                        onSorting={(field, order) =>
                                                            setSorting({ field, order })
                                                        }
                                                    />
                                                    <tbody>
                                                        {commentsData.map((comment, i) => {
                                                            const createDate = "SDfdsf" /*  dateFormat(
                                                                comment?.createdAt?.toDate(),
                                                                "dd-mm-yyyy"
                                                            ); */







                                                            return (
                                                                <tr key={i}>

                                                                    {/* <td className="GallaryImg" onClick={(e) => { imageviews(i) }} ><img src={comment?.image} alt="" /></td> */}

                                                                    <td>{comment?.title}</td>
                                                                    {/* <td>{comment?.area}</td>
                                                                    <td>{comment?.roomtype}</td>
                                                                    
                                                                    
                                                                    
                                                                    
                                                                    
                                                                <td>{comment?.Price}</td> */}
                                                                <td>{comment?.description?.length > 16? comment?.description.substring(0, 15)+"." : comment?.description }</td>
                                                                    <td className="actionicon">
                                                                        <div className="d-flex">


                                                                            {/* <a
                                                                               
                                                                                className="btn btn-primary shadow btn-xs sharp mr-1" onClick={(e) => { roomDetailsView("show", comment?.id) }}
                                                                            >
                                                                             <HiOutlineEye/> <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </a> */}


                                                                            <a
                                                                                // href="#delete" data-toggle="modal"
                                                                                className="btn btn-danger shadow btn-xs sharp" onClick={(e) => { handleShow(comment?.id) }}
                                                                            >
                                                                                {/* <MdDelete />  */} <i className="fa fa-trash"></i>
                                                                            </a>
                                                                            <a
                                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                               onClick={(e) => {
                                                                                  handleeditShow(comment);
                                                                               }}
                                                                             >
                                                                              <EditOutlined />
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* <div
                                            className="dataTables_info category"
                                            id="example3_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing {itemOffset} to {comments?.length < itemOffset + itemsPerPage ? comments?.length : itemOffset + itemsPerPage} of{" "}
                                         
                                            {comments?.length} entries
                                        </div>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next >"
                                            className="dataTables_paginate paging_simple_numbers category"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< previous"
                                            renderOnZeroPageCount={null}
                                        /> */}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {isOpen && (
                <Lightbox
                    mainSrc={comments[photoIndex].image}
                    nextSrc={comments[(photoIndex + 1) % comments.length]}
                    prevSrc={comments[(photoIndex + comments.length - 1) % comments.length]}
                    onCloseRequest={() => setisOpen(false)}
                    onMovePrevRequest={() => setphotoIndex((photoIndex + comments.length - 1) % comments.length)
                    }
                    onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % comments.length)
                    }
                />
            )}


            <div className="bs-example">
                <div id="category" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                                    Create Food Category
                                </h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body" style={{ padding: "26px" }}>
                                <div className="assignment_form loader-parent">
                                    <form>
                                        {/* <input

                                            type="file"
                                            className="form-control input-default "
                                            name="image"

                                            ref={ref}
                                            accept="image/*"
                                            onChange={
                                                handleImage}
                                        /> */}

                                        <div className="submitBtn">
                                            <button
                                                type="button"
                                                onClick={(e) => { sendMessagesss(e) }}
                                                className="btn btn-primary"
                                                data-dismiss="modal"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>

                                    <div
                                        className="loader-bx lodding "
                                        style={{ display: "none" }}
                                    >
                                        <img src="img/lodder.gif" alt="loader" className="center" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="bs-example">
                <div id="delete" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div>
                                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                                    Are you sure want to delete Image
                                </h5>
                                <div className="deleteBtn">

                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={hendledelete} >Yes</button>
                                    <button type="button" className="btn btn-outline-success" data-dismiss="modal">No</button>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div id='editDiv' style={{ display: 'none', border: "0.4px solid white", borderRadius: "10px", boxShadow: "1px 1px 5px 1px #888888", margin: "30px" }}>

                <div className="roomadd">
                    <div className="roomaddBack">

                        <h3>Add Food Category</h3>
                        <div className="roomaddBackB" onClick={(e) => { editUser("cencel") }} ><GrClose /> </div>
                    </div>


                    <form ref={ref} onSubmit={sendMessagesss}>
                    <div className="row">
                        {/* <div className="col-12 col-sm-6"> */}
                            {/* <input

                                type="file"
                                className="form-control input-default "
                                name="image"
                                ref={ref}
                                accept="image/*"
                                onChange={
                                    handleImage}
                            /> */}

                            {/* <input
                                type="file"
                                className="form-control input-default"
                                id="inputprofile"
                                // value={userDetailsUpdates?.profileImg}
                                name="profileImg"
                                placeholder="Please enter profile url"
                                autoComplete="off"
                                accept="image/*"
                                onChange={handleImage1}
                            /> */}
                        {/* </div> */}
                        {/* <div className="col-12 col-sm-6">
                            <input name="gueste" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Gueste Count" />
                        </div> */}
                        {/* <div className="col-12 col-sm-6 categorySelect">
                            <select name="title" onChange={(e) => { hendlesetRoomDetailsAdd(e) }}   >
                                <option>Select Category</option>
                                <option value={"rooms"}>Rooms</option>
                                <option value={"restaurant"}>restaurant</option>
                                <option value={"rooftop"}>Rooftop</option>
                                <option value={"bathroom"}>Bathroom</option>
                                <option value={"bannquet"}>Bannquet</option>
                                <option value={"confrenceHall"}>Confrence Hall</option>
                                <option value={"boardroom"}>Boardroom</option>
                                <option value={"swimingPool"}>Swiming Pool</option>
                                <option value={"valetParking"}>Valet Parking</option>

                            </select>
                        </div> */}




                        <div className="col-12 col-sm-6" >
                            <input name="title" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter title" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="description" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter description" />
                        </div>
                        
                       {/*  <div className="col-12 col-sm-6" >
                            <input name="aboutRoom" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter About Room" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="price" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Price" />
                        </div> */}




                        <div className="col-12 col-sm-12">
{/* 
                            <ReactCrop
                                style={{ maxWidth: "50%" }}
                                src={srcImg}
                                onImageLoaded={setImage}
                                crop={crop}
                                circularCrop={true}
                                onChange={setCrop}
                            /> */}

                            <a className="roomAdd" onClick={(e) => { sendMessagesss(e) }}>Submit</a>
                        </div>
                    </div>
</form>
                </div>
            </div>


            <div id='roomView' style={{ display: 'none', border: "0.4px solid white", borderRadius: "10px", boxShadow: "1px 1px 5px 1px #888888", margin: "30px" }}>

                <div className="roomadd">
                    <div className="roomaddBack">






                        <h3> Events</h3>
                        <div className="roomaddBackB" onClick={(e) => { roomDetailsView("cencel") }} ><GrClose /> </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            Gueste : {roomDetailsAbout?.gueste}
                        </div>
                        <div className="col-12 col-md-6">
                            Area : {roomDetailsAbout?.area}
                        </div>
                        <div className="col-12 col-md-6">
                            Room Type : {roomDetailsAbout?.roomtype}
                        </div>
                        <div className="col-12 col-md-6">
                            Price : {roomDetailsAbout?.Price}
                        </div>
                        <div className="col-12">
                            <div className="roomDImg">
                                <img src={roomDetailsAbout?.image} alt="" />
                            </div>

                        </div>
                        <div className="col-12">
                            About Room : {roomDetailsAbout?.aboutRoom}
                        </div>

                    </div>

                    {/* <h1>Proceesing</h1> */}

                </div>
            </div>


            <div className={srcImg ? "imageUploads  modal fade show" : "modal fade"} role="dialog" id="exampleModal121" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal={srcImg ? "true" : "flase"}>
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-body">


                            {srcImg && (
                                <div>
                                    <ReactCrop
                                        style={{ maxWidth: "50%" }}
                                        src={srcImg}
                                        onImageLoaded={setImage}
                                        crop={crop}
                                        // circularCrop={true}
                                        onChange={setCrop}
                                    />

                                    <button className="cropButton" onClick={(e) => { getCroppedImg(e) }}
                                    >
                                        crop
                                    </button>
                                </div>
                            )}




                        </div>

                    </div>
                </div>
            </div>




            <Modal show={show1} /* onHide={handleClose1} */>
                <Modal.Header >
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <div className="modal-body">


                    {srcImg && (
                        <div>
                            <ReactCrop
                                style={{ maxWidth: "50%" }}
                                src={srcImg}
                                onImageLoaded={setImage}
                                crop={crop}
                                // circularCrop={true}
                                onChange={setCrop}
                            />

                            <button className="cropButton" onClick={(e) => { getCroppedImg(e) }}
                            >
                                crop
                            </button>
                        </div>
                    )}




                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { getCroppedImg(e) }}>
                    crop
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose1}>
                        No
                    </Button> */}
                </Modal.Footer>
            </Modal>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <div>
                    <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                        Are you sure want to delete Category
                    </h5>

                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hendledelete}>
                        Yes
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editmoelOpen} onHide={handleeditClose}>
        <Modal.Header closeButton>
          {/* Add modal header content if needed */}
        </Modal.Header>
        <div>
          <h5 className="modal-title" style={{ padding: "8px 27px" }}>
            Are you sure want to edit Category1
          </h5>
      
   
          <div className="row editfield">
          <div className="col-12 col-sm-6">
            <input
              name="title"
              type="text"
              value={selectedComment?.title}
              onChange={(e) => {
                hendlesetedit(e);
              }}
              placeholder="Enter title"
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              name="description"
              type="text"
              value={selectedComment?.description}
              onChange={(e) => {
                hendlesetedit(e);
              }}
              placeholder="Enter description"
            />
          </div>
          </div>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditsubmit}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleeditClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

        </div>
    )
}

export default AdminCategory