import React, { useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../Firebase";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Reservations from "../Home/Reservations";
import Testimonial from "../Home/Testimonial";
import firebase from "firebase";
import PhoneInput from "react-phone-input-2";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

function Contact() {
  const [details, setDetails] = useState({ name: "", message: "" });
  const [mynumber, setmynumber] = useState("");

  const hendledetailsAdd = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const userMainPage = async () => {
    if (details?.name?.length < 1) {
      toast.error("Please Select name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // document.getElementById('id011').style.display = 'none'
    } else {


      const tokenDoc = await db.collection("admin").doc("token").get();

      if (tokenDoc.exists) {
        const token = tokenDoc.data().token;

        const baseUrl =
          "https://krishnapride.com/notify.php?token=" + token +
          "&name=" + details?.name +
          "&mobile=" + mynumber;

      var myHeaderss = new Headers();
      myHeaderss.append("Content-Type", "text/plain");
      myHeaderss.append("Access-Control-Allow-Origin", "*");
      myHeaderss.append("Access-Control-Allow-Credentials", "true");
      var raw = JSON.stringify({});
      var requestOptionss = {
        method: "get",
        mode: "no-cors",
        headers: myHeaderss,
        // body: raw,
        redirect: "follow",
      };
      fetch(baseUrl, requestOptionss)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result === "Successfully") {
          } else {
          }
        });


      const dones = await db.collection("contectEnquiry").add({
        name: details?.name,
        phoneNumber: "+" + mynumber,
        message: details?.message,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // document.getElementById('id011').style.display = 'none'
      // toast.success("Your Enquiry Submited")
      toast.success("Your Enquiry Submited", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else{
      console.error("Token document does not exist.");
    }
    }
  };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <section className="section contact-section bg-fade stylecontact"  style={{marginTop:"96px"}}id="next">
        <div className="container ">
          <h1 className="contact-heading">Contact Us</h1>
          <div className="row custom-contact-row">
            <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
              {/* <form action="#" method="post" className="bg-white p-md-5 p-4 mb-5 border"> */}
              <div className="row">
                <div className="col-md-12 form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control "
                    name="name"
                    onChange={(e) => {
                      hendledetailsAdd(e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group telinputno">
                  {/* <label for="phone">Phone</label> */}
                  {/* <input type="text" id="phone" className="form-control " /> */}
                  <PhoneInput
                    country={"in"}
                    value={mynumber}
                    onChange={setmynumber}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12 form-group">
                  <label for="message">Write Message</label>
                  <textarea
                    name="message"
                    id="message"
                    className="form-control "
                    onChange={(e) => {
                      hendledetailsAdd(e);
                    }}
                    cols="30"
                    rows="8"
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    className="btn btn-primary text-white font-weight-bold"
                    onClick={(e) => {
                      userMainPage(e);
                    }}
                  />
                </div>
              </div>
              {/* </form> */}
            </div>
            <div className="col-md-5" data-aos="fade-up" data-aos-delay="200">
              <div className="row">
                <div className="col-md-10 ml-auto contact-info">
                  <p>
                    <span className="d-block">Address:</span>{" "}
                    <span className="text-black">
                      {" "}
                      Nivik hospital, Udb Indus, Opposite Nikki road lines , 29
                      uday nagar b, Jaipur, Rajasthan 302020
                    </span>
                  </p>
                  <p>
                    <span className="d-block">Phone:</span>

                    <span className="text-black">+918005501767</span>
                    <br></br>
                    <span className="text-black">+919928992889</span>
                  </p>
                  <p>
                    <span className="d-block">Email:</span>{" "}
                    <span className="text-black">
                      {" "}
                      <a
                        href="mailto:Hotelkrishnapride@gmail.com"
                        className="__cf_email__"
                      >
                        Hotelkrishnapride@gmail.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonial />
      <Reservations />
      <Footer />
    </div>
  );
}

export default Contact;
