import React, { useEffect, useState } from "react";
import { db } from "../../Firebase";
import { useNavigate } from "react-router-dom";

function HomeRoom() {
  const [roomDetails, setroomDetails] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    db.collection("roomDetails")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });
        setroomDetails(oderdetails);
      });
  }, []);
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-7">
              <h2 className="heading" data-aos="fade-up">
                Rooms &amp; Suites
              </h2>
              <p data-aos="fade-up" data-aos-delay="100">
              The hotel offers a range of luxurious rooms and suites that are designed to cater to the needs of discerning travelers. Each room is tastefully furnished with modern amenities, including air conditioning, flat-screen TVs, and high-speed Wi-Fi. The rooms also offer stunning views of the city, making it the perfect place to relax after a long day of sightseeing or business meetings.
              </p>
            </div>
          </div>
          <div className="row">
            {roomDetails &&
              roomDetails?.map((data, i) => {
                return (
                  <div className="col-md-6 col-lg-4" data-aos="fade-up" key={i}>
                    <a
                      // onClick={(e) => {
                      //   navigate("book-room", { roomId: data?.id });
                      // }}
                      className="room"
                    >
                      <figure className="img-wrap eventImg">
                        <img
                          src={data?.image}
                          alt="Free website template"
                          className="img-fluid mb-3"
                        />
                      </figure>
                      <div className="p-3 text-center room-info">
                        <h2>{data?.roomtype}</h2>
                        <span className="text-uppercase letter-spacing-1">
                          &#8377;{data?.Price} / per night
                        </span>
                      </div>
                    </a>
                  </div>
                );
              })}

            {/* <div className="col-md-6 col-lg-4" data-aos="fade-up">
              <a href="#" className="room">
                <figure className="img-wrap">
                  <img src="image/3.png" alt="Free website template" className="img-fluid mb-3" />
                </figure>
                <div className="p-3 text-center room-info">
                  <h2>Single Room</h2>
                  <span className="text-uppercase letter-spacing-1">&#8377;700 / per night</span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4" data-aos="fade-up">
              <a href="#" className="room">
                <figure className="img-wrap">
                  <img src="image/2.jpeg" alt="Free website template" className="img-fluid mb-3" />
                </figure>
                <div className="p-3 text-center room-info">
                  <h2>Family Room</h2>
                  <span className="text-uppercase letter-spacing-1">&#8377;900 / per night</span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4" data-aos="fade-up">
              <a href="#" className="room">
                <figure className="img-wrap">
                  <img src="image/4.png" alt="Free website template" className="img-fluid mb-3" />
                </figure>
                <div className="p-3 text-center room-info">
                  <h2>Presidential Room</h2>
                  <span className="text-uppercase letter-spacing-1">&#8377;1400 / per night</span>
                </div>
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeRoom;
