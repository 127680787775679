import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../Firebase";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

function Menu() {
  const [Comments, setComments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [active, setactive] = useState(""); // Updated to empty string

  useEffect(() => {
    const unsub = db
      .collection("menu")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });
        setComments(oderdetails);

        // Extract unique categories from the data
        const uniqueCategories = Array.from(
          new Set(oderdetails.map((item) => item.category))
        );
        setCategories(uniqueCategories);

        // Set the first category as active by default
        if (uniqueCategories.length > 0 && !active) {
          setactive(uniqueCategories[0]);
        }
      });

    return () => {
      unsub();
    };
  }, [active]); // Include active as a dependency to avoid the eslint warning

  const commentsData = useMemo(() => {
    const listss = Comments?.filter((data) => {
      return data?.category === active;
    });
    return listss;
  }, [active, Comments]);



  return (
    <div>
       <section
        className="section bg-image overlay"
        style={{
          backgroundImage: `url(images/xhero_3.jpg.pagespeed.ic.kqV0yxkwP5.jpg)`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-7">
              <h2 className="heading text-white" data-aos="fade">
                Our Restaurant Menu
              </h2>
              {/* <p className="text-white" data-aos="fade" data-aos-delay="100">
                For those looking to host a conference or event, Krishna Pride
                has state-of-the-art banquet and conference halls that can
                accommodate up to 500 guests. The hotel's professional event
                planning team can assist with everything from catering to
                audio-visual equipment, ensuring that your event runs smoothly
                and seamlessly.
              </p> */}
            </div>
          </div>
          {/* 
          <option value={"Breakfast"}>Breakfast</option>
          <option value={"Beverage"}>Beverage</option>
          <option value={"Pizza and Chat"}>Pizza & Chat</option>
          <option value={"Soup"}>Soup</option>
          <option value={"Snacks"}>Snacks</option>
          <option value={"A La Carte"}>A La Carte</option>
          <option value={"Breads"}>Breads</option>
          <option value={"Rice"}>Rice</option>
          <option value={"Raita"}>Raita</option>
          <option value={"Dessert"}>Dessert</option>
          <option value={"Meal Combo"}>Meal Combo</option>
          <option value={"Egg Special"}>Egg Special</option>
          <option value={"Salad"}>Salad</option> */}

          <div className="food-menu-tabs"  blog-post-entry  data-aos="fade" >
          <div className="cyz" style={{ whiteSpace: 'nowrap', overflowX: 'auto', position: "relative",
    bottom:"37px" }} data-aos="fade-up" >
            <ul className="nav nav-tabs mb-5" id="myTab" role="tablist"  >
              {categories?.map((data, i) => (
                <li className="nav-item"  key={i}>
                  <a
                    className={
                      active === data
                        ? "nav-link active letter-spacing-2"
                        : "nav-link letter-spacing-2 testc"
                    }
                    role="tab"
                    aria-controls={data}
                    aria-selected="true"
                    onClick={() => {
                      setactive(data);
                    }}
                  >
                    {data}
                  </a>
                </li>
              ))}
            </ul>
</div>


          {/* <div className="food-menu-tabs" data-aos="fade"> */}
            {/* <ul className="nav nav-tabs mb-5" id="myTab" role="tablist">
              {list?.map((data, i) => {
                return (
                  <li className="nav-item" key={i}>
                    <a
                      className={
                        active == data
                          ? "nav-link active letter-spacing-2"
                          : "nav-link letter-spacing-2 testc"
                      }
                      //   id="mains-tab"
                      //   data-toggle="tab"
                      //   href={"#" + data}
                      role="tab"
                      aria-controls={data}
                      aria-selected="true"
                      onClick={() => {
                        setactive(data);
                      }}
                    >
                      {data}
                    </a>
                  </li>
                );
              })}

              {/* <li className="nav-item">
                <a
                  className="nav-link active letter-spacing-2"
                  id="mains-tab"
                  data-toggle="tab"
                  href="#mains"
                  role="tab"
                  aria-controls="mains"
                  aria-selected="true"
                >
                  Mains
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link letter-spacing-2"
                  id="desserts-tab"
                  data-toggle="tab"
                  href="#desserts"
                  role="tab"
                  aria-controls="desserts"
                  aria-selected="false"
                >
                  Desserts
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link letter-spacing-2"
                  id="drinks-tab"
                  data-toggle="tab"
                  href="#drinks"
                  role="tab"
                  aria-controls="drinks"
                  aria-selected="false"
                >
                  Drinks
                </a>
              </li> */}
            {/* </ul> */} 
            <div className="tab-content py-5" id="myTabContent">
              <div
                className="tab-pane fade show active text-left"
                // id="mains"
                // role="tabpanel"
                // aria-labelledby="mains-tab"
              >
                <div className="row">
                  {commentsData &&
                    commentsData?.map((data, i) => {
                      return (
                        <div className="col-md-6" key={i}>
                          <div className="food-menu mb-5">
                            <span className="d-block text-primary h4 mb-3">
                              &#8377;{data?.price}
                            </span>
                            <h3 className="text-white">
                              <a href="#" className="text-white">
                                {data?.name}
                              </a>
                            </h3>
                            <p className="text-white text-opacity-7">
                              {data?.description}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                  {/* <div className="col-md-6">
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;70
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          French Toast Combo
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;70
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Vegie Omelet
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;150
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Chorizo &amp; Egg Omelet
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div
                className="tab-pane fade text-left"
                id="desserts"
                role="tabpanel"
                aria-labelledby="desserts-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;80
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Banana Split
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;490
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Sticky Toffee Pudding
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;175
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Pecan
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;285
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Apple Strudel
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;66
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Pancakes
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;154
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Ice Cream Sundae
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>




              <div
                className="tab-pane fade text-left"
                id="drinks"
                role="tabpanel"
                aria-labelledby="drinks-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;240
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Spring Water
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;99
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Coke, Diet Coke, Coke Zero
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;560
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Orange Fanta
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;126
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Lemonade, Lemon Squash
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;275
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Sparkling Mineral Water
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                    <div className="food-menu mb-5">
                      <span className="d-block text-primary h4 mb-3">
                        &#8377;430
                      </span>
                      <h3 className="text-white">
                        <a href="#" className="text-white">
                          Lemon, Lime &amp; Bitters
                        </a>
                      </h3>
                      <p className="text-white text-opacity-7">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Menu;
