import React from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BiLocationPlus } from "react-icons/bi";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="section footer-section">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-2 mb-5">
              <ul className="list-unstyled link">
                <li>
                  <Link to={"/abouts"}>About Us </Link>
                  {/* <a href="#">About Us</a> */}
                </li>
                <li>
                  <Link to={"/"}>Terms &amp; Conditions </Link>
                  {/* <a href="#">Terms &amp; Conditions</a> */}
                </li>
                <li>
                  <Link to={"/"}>Privacy Policy </Link>
                  {/* <a href="#">Privacy Policy</a> */}
                </li>
                <li>
                  <Link to={"/events"}>Events </Link>
                  {/* <a href="#">Rooms</a> */}
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-5">
              <ul className="list-unstyled link">
                <li>
                  <Link to={"/gallery"}>Photos </Link>
                  {/* <a href="#">The Rooms &amp; Suites</a> */}
                </li>
                <li>
                  <Link to={"/abouts"}>About Us</Link>
                  {/* <a href="#">About Us</a> */}
                </li>
                <li>
                  <Link to={"/contact"}>Contact Us</Link>
                  {/* <a href="#">Contact Us</a> */}
                </li>
                <li>
                  <a href="#">Restaurant</a>
                </li>
              </ul>
            </div>
            <div className="col-md-5 mb-5 pr-md-5 contact-info">
              <p>
                <span className="d-block">
                  <span className=" h5 mr-3 text-primary">
                    <BiLocationPlus />
                  </span>
                  Address:
                </span>{" "}
                <span>
                  Nivik hospital, Udb Indus, Opposite Nikki road lines , 29 uday
                  nagar b, Jaipur, Rajasthan 302020
                  {/*                                 
                                198
                            103 Pashupati NATH Nagar Jaipur */}
                  {/*  West 21th Street, <br /> Suite 721 New York NY 10016 */}
                </span>
              </p>
              <p>
                <span className="d-block">
                  <span className=" h5 mr-3 text-primary">
                    <AiOutlinePhone />
                  </span>
                  Phone:
                </span>
               {/* ,9928992889 */}
                <span>+918005501767</span> ,
                <span>+919928992889</span>
              </p>
              <p>
                <span className="d-block">
                  <span className=" h5 mr-3 text-primary">
                    {" "}
                    <AiOutlineMail />
                  </span>
                  Email:
                </span>{" "}
                <span>
                  {" "}
                  <a
                    href="mailto:Hotelkrishnapride@gmail.com"
                    className="__cf_email__  "
                  >
                    Hotelkrishnapride@gmail.com
                  </a>
                </span>
              </p>
            </div>
            <div className="col-md-3 mb-5 social">
              <a
                href="https://www.facebook.com/hotelkrishna.pride"
                target={"_blank"}
              >
                <img src="/icon/fb.png" className="btmic" alt="image" />

                {/* <span className="fa fa-facebook"></span> */}
              </a>
              {/* <a href="#"><span className="fa fa-twitter"></span></a> */}
              <a
                href="https://in.linkedin.com/in/krishna-pride-79348a202?original_referer=https%3A%2F%2Fwww.google.com%2F"
                target={"_blank"}
              >
                <img src="/icon/linkedin.png" className="btmic" alt="image" />

                {/* <span className="fa fa-linkedin"></span> */}
              </a>
              {/* <a
                href="https://www.oyorooms.com/108517/?checkin=05%2F03%2F2022&checkout=06%2F03%2F2022&rooms=1&guests=2&rooms_config=1-2_0&selected_rcid=1"
                target={"_blank"}
              >
                <span className="fa fa-bed"></span>
              </a> */}
              <a
                href="https://www.booking.com/hotel/in/collection-o-77414-krishna.sl.html"
                target={"_blank"}
              >
                <img src="/icon/booking.png" className="btmic" alt="image" />
              </a>
              <a
                href="https://www.makemytrip.com/hotels/krishna_pride-details-jaipur.html"
                target={"_blank"}
              >
                <img src="/icon/my.png" className="btmic" alt="image" />
              </a>
              <a
                href="https://www.goibibo.com/hotels/krishna-pride-hotel-in-jaipur-5166527764276127328/"
                target={"_blank"}
              >
                <img src="/icon/go.png" className="btmic" alt="image" />
              </a>
              <a
                href="https://www.tripadvisor.com/Hotel_Review-g304555-d23516625-Reviews-Hotel_Krishna_Pride-Jaipur_Jaipur_District_Rajasthan.html"
                target={"_blank"}
              >
                <img src="/icon/trip.jpg" className="btmic" alt="image" />

                {/* <span className="fa fa-bed"></span> */}
              </a>
              <a href="https://goo.gl/maps/tKGU9sasQeE7TGK17" target={"_blank"}>
                <img src="/icon/gbg.png" className="btmic" alt="image" />

                {/* <span className="fa fa-bed"></span> */}
              </a>
              {/* <p>Sign up for our newsletter</p>
                            <form action="#" className="footer-newsletter">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Email..." />
                                    <button type="submit" className="btn"><span className="fa fa-paper-plane"></span></button>
                                </div>
                            </form> */}
            </div>
          </div>
          <div className="row pt-5">
            <p className="col-md-12 text-center">
              © 2021 KRISHNA PRIDE All rights reserved.
            </p>
            {/* <p className="col-md-6 text-right social">
                        
                            <a href="https://www.facebook.com/hotelkrishna.pride" target={"_blank"}><span className="fa fa-facebook"></span></a>
                            <a href="https://in.linkedin.com/in/krishna-pride-79348a202?original_referer=https%3A%2F%2Fwww.google.com%2F" target={"_blank"}><span className="fa fa-linkedin"></span></a>
                            <a href="https://www.oyorooms.com/108517/?checkin=05%2F03%2F2022&checkout=06%2F03%2F2022&rooms=1&guests=2&rooms_config=1-2_0&selected_rcid=1" target={"_blank"}><span className="fa fa-bed"></span></a>
                           
                        </p> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
