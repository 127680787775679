import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../Firebase";
import firebase from "firebase";

import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Reservations from "../Home/Reservations";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

function Gallery2() {
  const [comments, setComments] = useState([]);
  const [filterByC, setfilterByC] = useState("All");

  useEffect(() => {
    const unsub = db
      .collection("gallery")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });
        setComments(oderdetails);
      });

    return () => {
      unsub();
    };
  }, []);

  const commentsData = useMemo(() => {
    let computedComments = comments;
    if (filterByC == "All") {
      return (computedComments = comments);
    } else {
      return (computedComments = comments?.filter((data) => {
        return data.category == filterByC;
      }));
    }

    return computedComments;
  }, [comments, filterByC]);

  return (
    <div>
      {/* <ScrollToTop />
      <Header /> */}
      <section className="section blog-post-entry bg-fade" id="next">
        <div className="container">
          {/* <div className="knsl-filter knsl-mb-60 container  categoryImage">
            <a
              data-filter="*"
              className={
                filterByC == "All"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("All");
              }}
            >
              All Photos
            </a>

            <a
              data-filter=".hotel"
              className={
                filterByC == "rooms"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("rooms");
              }}
            >
              Rooms
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "restaurant"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("restaurant");
              }}
            >
              Restaurant
            </a>
            <a
              data-filter=".beach"
              className={
                filterByC == "valetParking"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("valetParking");
              }}
            >
              Valet Parking
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "swimingPool"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("swimingPool");
              }}
            >
              Swiming Pool
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "boardroom"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("boardroom");
              }}
            >
              Boardroom
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "confrenceHall"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("confrenceHall");
              }}
            >
              Confrence Hall
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "bannquet"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("bannquet");
              }}
            >
              Bannquet
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "bathroom"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("bathroom");
              }}
            >
              Bathroom
            </a>
            <a
              data-filter=".restaurant"
              className={
                filterByC == "rooftop"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("rooftop");
              }}
            >
              Roof Top
            </a>
          </div> */}
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-7">
              <h2 className="heading" data-aos="fade-up">
                Gallery
              </h2>
              {/* <p data-aos="fade-up">
                Krishna Pride is not just about luxury and comfort, though. The
                hotel is committed to sustainability and eco-friendliness. The
                hotel has implemented various measures to reduce its
                environmental impact, including energy-efficient lighting, waste
                reduction, and water conservation initiatives.
              </p> */}
            </div>
          </div>

          <div className="row mt-3">
            {commentsData &&
              commentsData?.map((data, i) => {
                return (
                  i < 6 && (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 post mb-5 cmGallery1"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      key={i}
                    >
                      <div className="media media-custom d-block mb-4 h-100">
                        <a
                          href={data?.image}
                          data-fancybox="images"
                          data-caption="Caption for this image"
                        >
                          <img
                            src={data?.image}
                            alt="Image placeholder"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  )
                );
              })}

            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12 post mb-5" data-aos="fade-up" data-aos-delay="200">
          <div className="media media-custom d-block mb-4 h-100">
            <a href="#" className="mb-4 d-block"><img src="image/events/22.png" alt="Image placeholder"
                className="img-fluid" /></a>
            <div className="media-body">
              <span className="meta-post">February 26, 2018</span>
              <h2 className="mt-0 mb-3"><a href="#">5 Job Types That Aallow You To Earn As You Travel The World</a></h2>
              <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 post mb-5" data-aos="fade-up" data-aos-delay="300">
          <div className="media media-custom d-block mb-4 h-100">
            <a href="#" className="mb-4 d-block"><img src="image/events/33.png" alt="Image placeholder"
                className="img-fluid" /></a>
            <div className="media-body">
              <span className="meta-post">February 26, 2018</span>
              <h2 className="mt-0 mb-3"><a href="#">30 Great Ideas On Gifts For Travelers</a></h2>
              <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. t is a
                paradisematic country, in which roasted parts of sentences.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 post mb-5" data-aos="fade-up" data-aos-delay="100">
          <div className="media media-custom d-block mb-4 h-100">
            <a href="#" className="mb-4 d-block"><img src="image/3.png" alt="Image placeholder" className="img-fluid" /></a>
            <div className="media-body">
              <span className="meta-post">February 26, 2018</span>
              <h2 className="mt-0 mb-3"><a href="#">Travel Hacks to Make Your Flight More Comfortable</a></h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                blind texts.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 post mb-5" data-aos="fade-up" data-aos-delay="200">
          <div className="media media-custom d-block mb-4 h-100">
            <a href="#" className="mb-4 d-block"><img src="image/4.png" alt="Image placeholder" className="img-fluid" /></a>
            <div className="media-body">
              <span className="meta-post">February 26, 2018</span>
              <h2 className="mt-0 mb-3"><a href="#">5 Job Types That Aallow You To Earn As You Travel The World</a></h2>
              <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 post mb-5" data-aos="fade-up" data-aos-delay="300">
          <div className="media media-custom d-block mb-4 h-100">
            <a href="#" className="mb-4 d-block"><img src="image/2222.png" alt="Image placeholder" className="img-fluid" /></a>
            <div className="media-body">
              <span className="meta-post">February 26, 2018</span>
              <h2 className="mt-0 mb-3"><a href="#">30 Great Ideas On Gifts For Travelers</a></h2>
              <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. t is a
                paradisematic country, in which roasted parts of sentences.</p>
            </div>
          </div>
        </div> */}
          </div>
          {/* <div className="row" data-aos="fade">
            <div className="col-12">
              <div className="custom-pagination">
                <ul className="list-unstyled">
                  <li className="active"><span>1</span></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><span>...</span></li>
                  <li><a href="#">30</a></li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="row justify-content-center">
            <Link
              to={"/gallery"}
              className="btn btn-primary text-white py-2 mr-3 evtBtn"
            >
              More
            </Link>
          </div>
        </div>
      </section>

      {/* <Reservations />
      <Footer /> */}
    </div>
  );
}

export default Gallery2;
