import React, { useState } from "react";
import { db } from "../../Firebase";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import dateFormat from "dateformat";

import { BiDownArrowAlt, BiCalendarAlt } from "react-icons/bi";
import axios from "axios";

import Slider from "react-slick";

function Banner() {
  const [mynumber, setmynumber] = useState("");
  const [details, setDetails] = useState("");
  const [person, setperson] = useState("1");
  const [childrens, setchildrens] = useState("0");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const video =
    "https://firebasestorage.googleapis.com/v0/b/hotel-sd-prime.appspot.com/o/images%2Fgallery%2FInShot_20220411_143427754%20(1).mp4?alt=media&token=6bb21a3d-ffea-42d4-9bfb-6ca6a9b2be58";

  const hendledetailsAdd = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const [videoLoad, setvideoLoad] = useState(true);
  const setVideoLoaded = () => {
    setvideoLoad(false);
  };

  const userMainPage = async () => {
    try {
    if (startDate == null) {
      toast.error("Please Select Check In Date", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("id01").style.display = "none";
    } else if (endDate === null) {
      // || startDate >= endDate
      toast.error("Please Select Check Out Date", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("id01").style.display = "none";
    } else {
      // const mynumber = 919928992889;

      const std = dateFormat(startDate, "dd-mm-yyyy");
      const end = dateFormat(endDate, "dd-mm-yyyy");

      // var config = {
      //   method: "post",
      //   mode: "no-cors",
      //   url: "https://wa.betablaster.in/api/send.php?number=919610641351&type=text&message=Clint%20Number%20%3D%20+919610641351%0AClint%20Name%20%3D%20lokesh%20kumawat%0AAdults%20%3D%201%0AChildren%20%3D%200%0ACheck%20in%20Date%20%3D%2007-09-2022%0ACheck%20out%20Date%20%3D%2007-09-2022&instance_id=63170A452EA13&access_token=e19e6ecbb0ec4719405f2c19d8bf96e2",
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "POST,PATCH,OPTIONS",
      //     "Content-Type": "application/json",
      //   },
      // };

      // axios(config)
      //   .then(function (response) {
      //     console.log(JSON.stringify(response.data));
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      const tokenDoc = await db.collection("admin").doc("token").get();

      if (tokenDoc.exists) {
        const token = tokenDoc.data().token;

        const baseUrl =
          "https://krishnapride.com/notify.php?token=" + token +
          "&name=" + details?.name +
          "&mobile=" + mynumber;

      // const baseUrl =
      //   "https://krishnapride.com/notify.php?token=" +
      //   "dHd6HgzZTi6EowmMY01p5S:APA91bEDxtRFRlik3HdXdUW6WK3kXtI_leCFpauxOrk-sqi-g04lL3zJfJ5QmGFPXtVlVO2q72My7OEBBRl8fKwFopg1Oea1ZGEH0m-B2OBay8OLrKR0YeffErAimGpTK07-6DzSdRFq" +
      //   "&name=" +
      //   details?.name +
      //   "&mobile=" +
      //   mynumber  
      var myHeaderss = new Headers();
      myHeaderss.append("Content-Type", "text/plain");
      myHeaderss.append("Access-Control-Allow-Origin", "*");
      myHeaderss.append("Access-Control-Allow-Credentials", "true");
      var raw = JSON.stringify({});
      var requestOptionss = {
        method: "get",
        mode: "no-cors",
        headers: myHeaderss,
        // body: raw,
        redirect: "follow",
      };
      // const response = await fetch(baseUrl, requestOptionss);
      // const result = await response.json();
      // console.log(result);

  
      fetch(baseUrl, requestOptionss)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result === "Successfully") {
          } else {
          }
        });
        
      const dones = await db.collection("userEnquiry").add({
        name: details?.name,
        checkInDate: startDate,
        checkOutDate: endDate,
        person: person,
        childrens: childrens,
        phoneNumber: "+" + mynumber,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      document.getElementById("id01").style.display = "none";
      // toast.success("Your Enquiry Submited")
      toast.success("Your Enquiry Submited", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.error("Token document does not exist.");
      // Handle the case where the token document doesn't exist
    }
  }
} catch (error) {
  console.error("Error fetching token:", error);
  // Handle the error appropriately
}
};
  const resPop = async (e) => {
    if (e == "show") {
      if (startDate == null) {
        toast.error("Please Select Check In Date", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (endDate == null) {
        toast.error("Please Select Check Out Date", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (localStorage.getItem("user_id")) {
        const uid = localStorage.getItem("user_id");

        db.collection("userDetails")
          .where("uid", "==", uid)
          .get()
          .then((snapshot) => {
            const oderdetails = snapshot.docs.map((e) => {
              const data = e.data();
              data.id = e.id;
              return data;
            });

            const dataafinal = oderdetails[0];

            const std = dateFormat(startDate, "dd-mm-yyyy");
            const end = dateFormat(endDate, "dd-mm-yyyy");
            // console.log("sfdsf");

            // var config = {
            //   method: "post",
            //   mode: "no-cors",
            //   url: "https://wa.betablaster.in/api/send.php?number=918619087233&type=text&message=Clint%20Number%20%3D%20+918619087233%0AClint%20Name%20%3D%20lokesh%20kumawat%0AAdults%20%3D%201%0AChildren%20%3D%200%0ACheck%20in%20Date%20%3D%2007-09-2022%0ACheck%20out%20Date%20%3D%2007-09-2022&instance_id=63170A452EA13&access_token=e19e6ecbb0ec4719405f2c19d8bf96e2",
            //   headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     "Access-Control-Allow-Methods": "POST,PATCH,OPTIONS",
            //     "Content-Type": "application/json",
            //   },
            // };

            // axios(config)
            //   .then(function (response) {
            //     console.log(JSON.stringify(response.data));
            //   })
            //   .catch(function (error) {
            //     console.log(error);
            //   });

            const baseUrl =
              "https://wa.betablaster.in/api/send.php?number=918619087233&type=text&message=Clint%20Number%20%3D%20" +
              dataafinal?.number +
              "%0AClint%20Name%20%3D%20" +
              dataafinal?.name +
              "%0AAdults%20%3D%20" +
              person +
              "%0AChildren%20%3D%20" +
              childrens +
              "%0ACheck%20in%20Date%20%3D%20" +
              std +
              "%0ACheck%20out%20Date%20%3D%20" +
              end +
              "&instance_id=63170A452EA13&access_token=e19e6ecbb0ec4719405f2c19d8bf96e2";
            var myHeaderss = new Headers();
            myHeaderss.append("Content-Type", "text/plain");
            myHeaderss.append(
              "Access-Control-Allow-Origin",
              "http://localhost:3002"
            );
            myHeaderss.append("Access-Control-Allow-Credentials", "true");
            var raw = JSON.stringify({});
            var requestOptionss = {
              method: "POST",
              mode: "no-cors",
              headers: myHeaderss,
              // body: raw,
              redirect: "follow",
            };
            fetch(baseUrl, requestOptionss)
              .then((response) => response.json())
              .then((result) => {
                console.log(result);
                if (result === "Successfully") {
                } else {
                }
              });

            const dones = db.collection("userEnquiry").add({
              name: dataafinal?.name,
              checkInDate: startDate,
              checkOutDate: endDate,
              person: person,
              childrens: childrens,
              phoneNumber: dataafinal?.number,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            // document.getElementById('id01').style.display = 'none'
            // toast.success("Your Enquiry Submited")
            toast.success("Your Enquiry Submited", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setStartDate(null);
          });
      } else {
        document.getElementById("id01").style.display = "block";
      }
    } else {
      document.getElementById("id01").style.display = "none";
    }
  };
  

  return (
    <div>
      {/* <section
        className="site-hero overlay"
        style={{ backgroundImage: `url(image/slider_2.jpg)` }}
        data-stellar-background-ratio="0.5"
      >
        {/* <div className="container">
          <div className="row site-hero-inner justify-content-center align-items-center">
            <div className="col-md-10 text-center" data-aos="fade-up">
              <span className="custom-caption text-uppercase text-white d-block  mb-3">
                Welcome To 5 <span className="fa fa-star text-primary"></span>{" "}
                Hotel
              </span>
              <h1 className="heading">A Best Place To Stay</h1>
            </div>
          </div>
        </div> */}
        {/* <a className="mouse smoothscroll" href="#next">
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </a>
      </section> */}

      <Slider {...settings}>
          <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/HOTEL.jpg" alt="" />
            {/* <div className=" mainBoxhomecon">
              <div className="container">
                <div className="row site-hero-inner justify-content-center align-items-center">
                  {/* <div className="col-md-10 text-center" data-aos="fade-up">
                    <span className="custom-caption text-uppercase text-white d-block  mb-3">
                      Welcome To 5{" "}
                      <span className="fa fa-star text-primary"></span> Hotel
                    </span>
                    <h1 className="heading">A Best Place To Stay</h1>
                  </div> */}
                {/* </div>
              </div>
            </div> */} 
            <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="image/slider_2.png" alt="" />
            {/* <div className=" mainBoxhomecon">
              <div className="container">
                <div className="row site-hero-inner justify-content-center align-items-center">
                  <div className="col-md-10 text-center" data-aos="fade-up">
                    <span className="custom-caption text-uppercase text-white d-block  mb-3">
                      Welcome To 5{" "}
                      <span className="fa fa-star text-primary"></span> Hotel
                    </span>
                    <h1 className="heading">A Best Place To Stay</h1>
                  </div>
                </div>
              </div>
            </div> */}
            <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
      <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/c-min.jpeg" alt="" />
            {/* <div className=" mainBoxhomecon">
              <div className="container">
                <div className="row site-hero-inner justify-content-center align-items-center">
                  <div className="col-md-10 text-center" data-aos="fade-up">
                    <span className="custom-caption text-uppercase text-white d-block  mb-3">
                      Welcome To 5{" "}
                      <span className="fa fa-star text-primary"></span> Hotel
                    </span>
                    <h1 className="heading">A Best Place To Stay</h1>
                  </div>
                </div>
              </div>
            </div> */}
            <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
      <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/slider_2-min.png" alt="" />
            {/* <div className=" mainBoxhomecon">
              <div className="container">
                <div className="row site-hero-inner justify-content-center align-items-center">
                  <div className="col-md-10 text-center" data-aos="fade-up">
                    <span className="custom-caption text-uppercase text-white d-block  mb-3">
                      Welcome To 5{" "}
                      <span className="fa fa-star text-primary"></span> Hotel
                    </span>
                    <h1 className="heading">A Best Place To Stay</h1>
                  </div>
                </div>
              </div>
            </div> */}
            <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
         </div>  
  <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/2-2-min.jpg" alt="" />
          {/* <div className=" mainBoxhomecon">
              <div className="container">
                <div className="row site-hero-inner justify-content-center align-items-center">
                  <div className="col-md-10 text-center" data-aos="fade-up">
                    <span className="custom-caption text-uppercase text-white d-block  mb-3">
                      Welcome To 5{" "}
                      <span className="fa fa-star text-primary"></span> Hotel
                    </span>
                    <h1 className="heading">A Best Place To Stay</h1>
                  </div>
                </div>
              </div>
            </div> */}
            <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          {/* <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/slider_2.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>  */}
          {/* <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/slider-2-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>  */}
          <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/_DSC0617-min.JPG" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div> 
          <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/SIDE.PNG" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div> 
          <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/b-min.jpeg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div> 
          {/* <div className="mainBoxhome">
            <img className="slidImg" src="sliderimg/slider_3-min.png" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>  */}
          {/* <div className="mainBoxhome">
            <img className="slidImg" src="images/3-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div> 
          <div className="mainBoxhome">
            <img className="slidImg" src="images/6-3-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div> 
          <div className="mainBoxhome">
            <img className="slidImg" src="images/6-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/1-4-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/2-3-min.jpg " alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/9-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/3-1-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/3-3-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/6-1-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/4-1-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/1-1-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/2-2-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/_DSC0617.JPG" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/1 (1)-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="images/1 (1)-min.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div>
          <div className="mainBoxhome">
            <img className="slidImg" src="image/slider_2.jpg" alt="" />
                   <a className="mouse smoothscroll" href="#next">
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </div> */}
   </Slider>

      <section className="section bg-light pb-0">
        <div className="container">
          <div className="row check-availabilty" id="next">
            <div className="block-32" data-aos="fade-up" data-aos-offset="-200">
              {/* <form > */}
              <div className="row">
                <div className="col-md-6 mb-3 mb-lg-0 col-lg-3">
                  <label
                    for="checkin_date"
                    className="font-weight-bold text-white"
                  >
                    Check In
                  </label>
                  <div className="field-icon-wrap">
                    <div className="icon">
                      {" "}
                      <BiCalendarAlt />
                    </div>
                    {/*    <input type="date" id="checkin_date" className="form-control" placeholder="Select date" /> */}
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // showTimeSelect
                      // timeFormat="HH:mm:ss"
                      className="form-control"
                      minDate={new Date()}
                      placeholderText="Select date"
                      // dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3 mb-lg-0 col-lg-3">
                  <label
                    for="checkout_date"
                    className="font-weight-bold text-white"
                  >
                    Check Out
                  </label>
                  <div className="field-icon-wrap">
                    <div className="icon">
                      <BiCalendarAlt />
                      {/* <span className="icon-calendar">
                         </span> */}
                    </div>

                    {/* <input type="date" id="checkout_date" className="form-control" /> */}
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setendDate(date)}
                      // showTimeSelect
                      // timeFormat="HH:mm:ss"
                      className="form-control"
                      minDate={new Date()}
                      placeholderText="Select date"
                      // dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3 mb-md-0 col-lg-3">
                  <div className="row">
                    <div className="col-6 mb-3 mb-md-0">
                      <label
                        for="adults"
                        className="font-weight-bold text-white"
                      >
                        Adults
                      </label>
                      <div className="field-icon-wrap Adults">
                        <div className="icon">
                          <BiDownArrowAlt />
                          {/* <span className="ion-ios-arrow-down"></span> */}
                        </div>

                        <select
                          name=""
                          id="adults"
                          onChange={(e) => {
                            setperson(e.target.value);
                          }}
                          className="form-control"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4+">4+</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6 mb-3 mb-md-0">
                      <label
                        for="children"
                        className="font-weight-bold text-white"
                      >
                        Children
                      </label>
                      <div className="field-icon-wrap children">
                        <div className="icon">
                          <BiDownArrowAlt />
                          {/* <span className="ion-ios-arrow-down"></span> */}
                        </div>
                        <select
                          name=""
                          id="children"
                          onChange={(e) => {
                            setchildrens(e.target.value);
                          }}
                          className="form-control"
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4+">4+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 align-self-end">
                  <button
                    className="btn btn-primary btn-block text-white"
                    onClick={(e) => {
                      resPop("show");
                    }}
                  >
                    Check Availabilty
                  </button>
                </div>
                {/* <div className="col-12 col-lg-1 text-center">
                    <div className="knsl-btn" onClick={(e) => { resPop("show") }}  ><img src="img/icons/search.svg" className="knsl-zoom" alt="icon" /><span>Serch room</span></div>
                  </div> */}
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                type={"text"}
                name="name"
                className="form-control "
                onChange={(e) => {
                  hendledetailsAdd(e);
                }}
              />

              <PhoneInput
                country={"in"}
                value={mynumber}
                onChange={setmynumber}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className={"btn btn-primary"}
                onClick={(e) => {
                  userMainPage(e);
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="id01" className="modal">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
        </div>

        <form className="modal-content resModel animate">
          <div className="row">
            <div className="col-1"></div>
            {/* <div className="col-md-5">
                                                    <div className="container">

                                                        <div className="imgcontainer">

                                                            <img src="img/png.png" alt="Avatar" className="" />
                                                        </div>
                                                       
                                                    </div>
                                                </div> */}

            <div className="col-10">
              <h4 className="login_page"> Your Details </h4>

              <input
                type={"text"}
                name="name"
                className="form-control "
                placeholder="Enter your name"
                onChange={(e) => {
                  hendledetailsAdd(e);
                }}
              />

              <PhoneInput
                country={"in"}
                value={mynumber}
                onChange={setmynumber}
              />

              <div className="enSubBtn">
                <button
                  type="button"
                  className={
                    details?.name?.length < 1
                      ? "btn btn-primary  enSub  disBtn  "
                      : mynumber?.length < 10
                      ? "btn btn-primary  enSub disBtn "
                      : "btn btn-primary  enSub"
                  }
                  onClick={(e) => {
                    userMainPage(e);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="col-1">
              <span
                className="close"
                onClick={(e) => {
                  resPop("close");
                }}
                title="Close Modal"
              >
                &times;
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Banner;
