import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import OTPInput, { ResendOTP } from "otp-input-react";
import { auth, db, firebase } from "../Firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const navigate = useNavigate();

  const [show, setShow] = useState("0");
  const [showotpBox1, setshowotpBox1] = useState("0");
  const [showotpBox2, setshowotpBox2] = useState("0");
  const [Loginshow, setLoginShow] = useState("0");
  const [registershow, setregistershow] = useState("0");

  const [otp, setOtp] = useState("");
  const [final, setfinal] = useState("");
  const [mynumber, setmynumber] = useState("");
  const [name, setname] = useState("");

  const hendleShow = (e) => {
    if (show == "0") {
      setShow("1");
    } else {
      setShow("0");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token_K_a")) {
      navigate("/admin");
    }
  });

  const signupHendle = () => {
    if (name == "") {
      toast.error("name is required");
    } else if (mynumber == "") {
      toast.error("number is required");
    } else if (mynumber.length < 10) {
      toast.error("Invalid Mobile Number");
    } else {
      db.collection("userDetails")
        .where("number", "==", "+" + mynumber)
        .get()
        .then((snapshot) => {
          const userCards = snapshot.docs.map((doc) => {
            const userData = doc.data();
            userData.id = doc.id;
            return userData;
          });

          if (userCards?.length == 1) {
            toast.error("Alredy Register");
            setmynumber("");
            setname("");
          } else {
            signin();
          }
        });
    }
  };

  const LoginHendle = () => {
    if (
      mynumber == "919610641351" ||
      mynumber == "919314267002" ||
      mynumber == "919928992889"
    ) {
      // db
      //     .collection('userDetails').where("number", "==", "+" + mynumber)
      //     .get()
      //     .then(snapshot => {

      //         const userCards = snapshot.docs.map(doc => {
      //             const userData = doc.data()
      //             userData.id = doc.id
      //             return userData
      //         })

      //         if (userCards?.length == 1) {
      signin();
      //     } else {
      //         toast.error("Your Account not Create Please Register");
      //         setmynumber("")
      //     }
      // });
    } else {
      toast.error("Invalid number");
    }
  };

  const login = (e) => {
    // if (otp == "Admin#123" && mynumber == "billy247@gmail.com") {
    toast.success("Enter Otp");
    setshowotpBox1("1");
    setshowotpBox2("1");
    //   localStorage.setItem("phone_Number", mynumber);
    //   navigate("/");
    // } else {
    //   toast.error("invalid email and password");
    // }

    // setshow(true);
  };

  const otpV = (e) => {
    if (otp == "118899") {
      toast.success("Login successfully");
      localStorage.setItem("access_token_K_a", mynumber);
      //   localStorage.setItem("phone_Number", mynumber);
      setshowotpBox2("0");
      setLoginShow("0");
      navigate("/admin");
    } else {
      toast.error("invalid otp");
    }
  };

  // Sent OTP
  const signin = (e) => {
    // e.preventdefault()

    // if (mynumber === "" || mynumber.length < 10) return;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
          // console.log("Recaptca varified")
        },
        defaultCountry: "IN",
      }
    );

    const appVerifier = window.recaptchaVerifier;
    auth
      .signInWithPhoneNumber("+" + mynumber, appVerifier)

      .then((result) => {
        setfinal(result);
        setshowotpBox1("1");
        setshowotpBox2("1");
        toast.success("otp sent your number");

        // setshow(true);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  // Validate OTP
  const ValidateOtp = (e) => {
    e.preventDefault();

    if (otp === null || final === null) return;

    final
      .confirm(otp)
      .then(async (result) => {
        localStorage.setItem("user_id", result?.user?.uid);

        const dones = await db.collection("userDetails").add({
          number: result?.user?.phoneNumber,
          name: name,
          uid: result?.user?.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        toast.success("Register successfully");
        setshowotpBox1("0");
        setregistershow("0");

        navigate("/");

        // history.push("/edit-details", { user: id?.state?.userleft })
      })
      .catch((err) => {
        toast.error("erong Otp");
        // alert("Wrong code");
      });
  };

  const LoginValidateOtp = (e) => {
    e.preventDefault();

    if (otp === null || final === null) return;

    final
      .confirm(otp)
      .then(async (result) => {
        localStorage.setItem("access_token_K_a", result?.user?.uid);
        toast.success("Login successfully");
        setshowotpBox2("0");
        setLoginShow("0");
        navigate("/admin");
        // const dones = await db.collection("userDetails").add({
        //   number: result?.user?.phoneNumber,
        //   name: "lokesh",
        //   uid: result?.user?.uid,
        //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        // });

        // history.push("/edit-details", { user: id?.state?.userleft })
      })
      .catch((err) => {
        toast.error("erong Otp");
        // alert("Wrong code");
      });
  };

  return (
    <div className="container">
      <div className="loginBox">
        <div class="row custom-contact-row">
          <div class="col-12" data-aos="fade-up" data-aos-delay="100">
            {/* <form action="#" method="post" class="bg-white p-md-5 p-4 mb-5 border"> */}

            {showotpBox2 == "0" ? (
              <div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    {/* <label for="phone">Phone</label> */}
                    {/* <input type="text" id="phone" class="form-control " /> */}
                    <PhoneInput
                      country={"in"}
                      value={mynumber}
                      onChange={setmynumber}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <input
                      type="submit"
                      value="Send Otp"
                      class="btn btn-primary text-white font-weight-bold"
                      onClick={(e) => {
                        login(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label for="name">Otp</label>
                    {/* <input type="text" id="name" class="form-control " name='name'  /> */}

                    <OTPInput
                      className="otpInput"
                      value={otp}
                      onChange={setOtp}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                      secure
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <input
                      type="submit"
                      value="Verify"
                      class="btn btn-primary text-white font-weight-bold"
                      onClick={(e) => {
                        otpV(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* </form> */}
          </div>
        </div>
      </div>

      <div id="sign-in-button"></div>
    </div>
  );
}

export default AdminLogin;
