import React, { useState, useEffect } from 'react'
import { Navbar, Col, Row, Modal, Button } from 'react-bootstrap';
import styled from 'styled-components'
import { AiOutlineDoubleRight, AiFillHome, AiOutlineTrophy, AiOutlineSetting } from 'react-icons/ai';
import { BiUserCircle, BiLogOutCircle } from 'react-icons/bi';
import { FaGamepad } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';
// import { BsStar, BsWallet2 } from 'react-icons/bs';
import { MdReviews, MdFamilyRestroom } from 'react-icons/md';
import { GoRequestChanges } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ImSearch } from 'react-icons/im';
import Deshboard from './Deshboard';

import { db } from '../Firebase';




const AdminPage = () => {

    const navigate = useNavigate()

    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);




    const handleShow = (e) => {
        setShow(true)
    };

    const logOutAdmin = () => {
        localStorage.removeItem('access_token_K_a')
        setShow(false)
        navigate('/admin/login')
    }







    const [enquiy, setenquiy] = useState([])
    const [conenquiy, setconenquiy] = useState([])
    const [events, setevents] = useState([])
    const [reviews, setreviews] = useState([])
    const [rooms, setrooms] = useState([])
    const [gallry, setgallry] = useState([])
    const [menu, setmenu] = useState([])
    const [category, setcategory] = useState([])




    useEffect(() => {
        const unSub = db
            .collection('userEnquiry').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
                const oderdetails = snapshot.docs.map((e) => {
                    const data = e.data()
                    data.id = e.id
                    return data
                });
                setenquiy(oderdetails)
            })
        return () => {
            unSub()
        }
    }, [])



    useEffect(() => {
        const unSub = db.collection('events').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setevents(oderdetails)
        })
        return () => {
            unSub()
        }


    }, [])
    useEffect(() => {
        const unSub = db.collection('contectEnquiry').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setconenquiy(oderdetails)
        })
        return () => {
            unSub()
        }


    }, [])

    useEffect(() => {
        const unSub = db.collection('userReview').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setreviews(oderdetails)
        })


        return () => {
            unSub()
        }
    }, [])

    useEffect(() => {
        const unSub = db.collection('roomDetails').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setrooms(oderdetails)
        })
        return () => {
            unSub()
        }
    }, [])
    useEffect(() => {
        const unSub = db.collection('menu').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setmenu(oderdetails)
        })
        return () => {
            unSub()
        }
    }, [])

    useEffect(() => {
        const unSub = db.collection('category').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setcategory(oderdetails)
        })
        return () => {
            unSub()
        }
    }, [])








    useEffect(() => {
        const unsub = db.collection('gallery').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setgallry(oderdetails)
        })

        return () => {
            unsub()
        }
    }, [])


    return (
        <>
            <div>
                <div>
                    <NavbarDiv bg="light" expand="lg" >

                        <Row>
                            <Col style={{ cursor: 'pointer', marginLeft: '2rem' }}>
                                {/* <AiOutlineDoubleRight clicked={click} onClick={() => handleClick()} style={{ height: '2rem', width: '2rem' }} /> */}
                            </Col>

                            <Col style={{ marginLeft: '3rem' }}>
                                <h4>Dashboard</h4>
                            </Col>

                        </Row>

                        <div style={{ cursor: 'pointer', marginRight: '4rem', float: "right" }}>
                            <p className='btn btn-primary text-white py-2 mr-3 ' onClick={logOutAdmin}>Logout</p>
                        </div>


                    </NavbarDiv>
                </div>

                {/* Side Bar */}

                {/* <a className='sc-eCImPb Goxgq' onClick={(e) => handleShow(e)}>
                                <BiLogOutCircle />
                                <Text clicked={click}>Logout</Text>
                            </a> */}


                <div className='dashboardContaint'>
                    <div className='container'>


                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard' >
                                    <Link to={"/admin/enquiry"}>
                                        <h5>Enquiry</h5>
                                        <p>{enquiy?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className='col-12 col-sm-6'>
                                    <div className='dashboardCard'>
                                        <Link to={"/admin/reviews"}>
                                            <h5>Reviews</h5>
                                            <p>{reviews?.length}</p>
                                        </Link>
                                    </div>
                                </div> */}
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard'>
                                    <Link to={"/admin/rooms"}>
                                        <h5>Rooms</h5>
                                        <p>{rooms?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard'>
                                    <Link to={"/admin/contact-enqury"}>
                                        <h5>Contect Enquiry</h5>
                                        <p>{conenquiy?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard'>
                                    <Link to={"/admin/events"}>
                                        <h5>Events</h5>
                                        <p>{events?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard'>
                                    <Link to={"/admin/gallery"}>
                                        <h5>Gallery</h5>
                                        <p>{gallry?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard'>
                                    <Link to={"/admin/menu"}>
                                        <h5>Menu</h5>
                                        <p>{menu?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='dashboardCard'>
                                    <Link to={"/admin/category"}>
                                        <h5>Category</h5>
                                        <p>{category?.length}</p>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className='col-12 col-sm-6'>
                    <div className='dashboardCard'>
                        <h5>Enquiry</h5>
                        <p>10</p>
                    </div>
                </div> */}

                        </div>


                    </div>
                </div>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <div>
                        <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                            Are you sure want to Logout
                        </h5>

                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={logOutAdmin}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginTop: '15rem', padding: '3rem' }}>
                        <h4>Welcome Dashboard </h4>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default AdminPage


const NavbarDiv = styled(Navbar)`
    box-shadow: 0px 0px 5px 1px #888888;
    height: 4rem
`;

const Containers = styled.div`
position: fixed;
.active {
  border-right: 4px solid var(--white);
  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
      brightness(103%) contrast(103%);
  }
}
`;


const SidebarContainer = styled.div`
background-color: var(--black);
width: 3.5rem;
margin-top: 1rem;
border-radius: 0 30px 30px 0;
padding: 1rem 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
position: relative;
`;


const SlickBar = styled.ul`
box-shadow: 0px 0px 5px 1px #888888;
color: var(--white);
list-style: none;
display: flex;
flex-direction: column;
align-items: center;
background-color: black;
padding: 1rem 0;
position: absolute;
top: 0rem;
left: 0;
width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
transition: all 0.5s ease;
border-radius: 0 30px 30px 0;
`;

const Item = styled(Link)`
text-decoration: none;
color: gray;
width: 100%;
padding: 1rem 0;
cursor: pointer;
display: flex;
padding-bottom: 0rem;
padding-left: 1rem;
&:hover {
  border-right: 4px solid var(--white);
  svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
      brightness(103%) contrast(103%);
  }
}
svg {

  width: 1.2rem;
  height: auto;
  filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
    brightness(78%) contrast(85%);
}
`;

const Text = styled.span`
width: ${(props) => (props.clicked ? "100%" : "0")};
overflow: hidden;
margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
transition: all 0.3s ease;
`;