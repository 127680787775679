import React, { useState, useEffect, useMemo, useRef } from "react";
// import { Link, useHistory } from "react-router-dom";
// import dateFormat from "dateformat";
// import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../Firebase";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { TableHeader, Pagination, Search } from "../Table/DataTable";
import AdminBackNav from "./AdminBackNav";
import { Modal, Button } from "react-bootstrap";
import { MdDelete, Md1KPlus } from "react-icons/md";
import { ImPlus } from "react-icons/im";
import { HiLockClosed, HiPlusCircle, HiOutlineEye } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { EditOutlined } from "@mui/icons-material";

function Menu() {
  const ref = useRef();
  const [RoomDetails, setRoomDetails] = useState({});
  const [deleteCategory, setdeleteCategory] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10000000000000000);
  const [comments, setComments] = useState([]);
  const [file, setFile] = useState(null);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const imageviews = (e) => {
    setisOpen(true);
    setphotoIndex(e);
  };



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setdeleteCategory(e);
    setShow(true);
  };



  const handleImage = (event) => {
    setFile(event.target.files[0]);
  };
  async function sendMessagesss(e) {
    e.preventDefault();

    const abc = db.collection("menu").add({
      // image: url,
      
      name: RoomDetails?.name,
      category: RoomDetails?.category,
      description: RoomDetails?.description,
      price: RoomDetails?.price,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    toast.success("successfully Add");
    document.getElementById("editDiv").style.display = "none";
    document.getElementById("roomsShow").style.display = "block";
  }

  const reset = () => {
    ref.current.value = "";
  };

  useEffect(() => {
    const unsub = db
      .collection("menu")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });
        setComments(oderdetails);
      });

    return () => {
      unsub();
    };
  }, []);

  const hendledelete = async (e) => {
    setShow(false);
    const res = await db.collection("menu").doc(deleteCategory).delete();
    toast.success("deleted ");
  };

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  const hendlesetRoomDetailsAdd = (e) => {
    const { name, value } = e.target;
    setRoomDetails({ ...RoomDetails, [name]: value });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    { name: "Name", field: "name", sortable: false },
    // { name: "Number", field: "number", sortable: false },
    { name: "Price", field: "price", sortable: false },
    { name: "Category", field: "category", sortable: false },
    { name: "Description", field: "discription", sortable: false },
    // { name: "aboutRoom", field: "aboutRoom", sortable: false },
    // { name: "Price", field: "Price", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;
    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected % (comments.length / 2);
    setCurrentPage(newOffset + 1);
    setItemOffset(newOffset * itemsPerPage);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(comments.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, comments]);

  const editUser = (val, rowData) => {
    if (val === "add") {
      document.getElementById("editDiv").style.display = "block";
      document.getElementById("roomsShow").style.display = "none";
    }
    if (val === "cencel") {
      document.getElementById("editDiv").style.display = "none";
      document.getElementById("roomsShow").style.display = "block";
    }
  };

  const [roomDetailsAbout, setRoomDetailsAbout] = useState({});
  const [roomId, setroomId] = useState();

  const roomDetailsView = (val, rowData) => {
    // console.log(rowData);
    if (val === "show") {
    
      db.collection("roomDetails")
        .doc(rowData)
        .get()
        .then((snapshot) => {
          setRoomDetailsAbout(snapshot?.data());
        });

      document.getElementById("roomView").style.display = "block";
      document.getElementById("roomsShow").style.display = "none";
    }
    if (val === "cencel") {
      document.getElementById("roomView").style.display = "none";
      document.getElementById("roomsShow").style.display = "block";
    }
  };


  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsub = db.collection("category").onSnapshot((snapshot) => {
      const categoryData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Category Data:", categoryData);
      setCategories(categoryData);
    });
  
    return () => {
      unsub();
    };
  }, []);
  
  
<ul>
  {categories.map((category) => (
    <li key={category.id}>{category.name}</li>
  ))}
</ul>
 
 const [editCategory, setEditCategory] = useState(null);

  // Function to handle the edit action
  const handleEditCategory = (categoryId) => {
    setEditCategory(categoryId);
    // You can perform any additional actions needed for editing here
  };



  
  const [selectedComment, setSelectedComment] = useState(null);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [editmoelOpen, seteditmoelOpen] = useState(false);
  const [editedData, setEditedData] = useState({
    name: '',
    category: '',
    description: '',
    price: '',
  });
console.log(selectedComment)
  const handleeditShow = (comment) => {
      // const selectedCommentData = commentsData.find(comment => comment.id === commentId);
  
      // Set the selected comment to the state
      setSelectedComment(comment);
    seteditmoelOpen(true);
    console.log(comment)
  };

  const handleeditClose = () => {
    seteditmoelOpen(false);
  };

  const hendlesetedit = (e) => {
      const { name, value } = e.target;
      setSelectedComment({ ...selectedComment,[name]: value }
   );
  };
  // ... (your existing code)

const handleeditsubmit = async () => {
  try {
    // Update the document in the 'category' collection with the edited data
    await db.collection('menu').doc(selectedComment.id).update({
      name: selectedComment.name,
      category: selectedComment.category,
      description: selectedComment.description,
      price: selectedComment.price,
      category: selectedComment.category,
      // Add other fields as needed
    });

    // Show success toast
    toast.success("Successfully updated");

    // Reset the form and close the modal
    seteditmoelOpen(false);
    setSelectedComment({
      name: '',
      category: '',
      description: '',
      description: '',
  
      // Add other fields as needed
    });
  } catch (error) {
    console.error("Error updating document: ", error);
    // Handle the error (show an error toast, etc.)
  }
};







  return (
    <div>
      <AdminBackNav />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a>Menu</a>
              </li>
            </ol>
          </div>

          <div className="row" id="roomsShow">
            <div className="col-12">
              <div className="card">
                <div className="card-header roomT">
                  <h4 className="card-title">Menu</h4>

                  <div className="addPlus">
                    <a
                      // href="#category"
                      className="login_button newQue"
                      // data-toggle="modal"
                      onClick={(e) => {
                        editUser("add");
                      }}
                    >
                      {/* Add */}

                      {/* <HiPlusCircle /> */}
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    {/* <div
                                            className="dataTables_length category"
                                            id="example3_length"
                                        >
                                            <label>
                                                Show{" "}
                                                <div className="dropdown category">
                                                    <select
                                                        name="example3_length"
                                                        aria-controls="example3"
                                                        className=""
                                                        tabIndex="-98"
                                                        onChange={(e) => {
                                                            hendleitemsPerPage(e);
                                                        }}
                                                    >
                                                        <option value={"10"}>10</option>
                                                        <option value={"25"}>25</option>
                                                        <option value={"50"}>50</option>
                                                        <option value={"100"}>100</option>
                                                    </select>
                                                </div>{" "}
                                                entries
                                            </label>
                                        </div> */}
                    {/* <div
                                            id="example3_filter"
                                            className="dataTables_filter category"
                                        >


                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />

                                        </div> */}

                    <div className="row w-100 tableResponsiv">
                      <div className="col mb-3 col-12 text-center">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData.map((comment, i) => {
                              const createDate = "SDfdsf"; /*  dateFormat(
                                                                comment?.createdAt?.toDate(),
                                                                "dd-mm-yyyy"
                                                            ); */

                              return (
                                <tr key={i}>
                                  {/* <td className="GallaryImg" onClick={(e) => { imageviews(i) }} ><img src={comment?.image} alt="" /></td> */}

                                  <td>{comment?.name}</td>
                                  <td>{comment?.price}</td>
                                  <td>{comment?.category}</td>
                                  <td>
                                    {comment?.description?.length > 50
                                      ? comment?.description.substring(0, 50) +
                                        "."
                                      : comment?.description}
                                  </td>
                                  {/* <td>{comment?.description}</td> */}
                                  {/* <td>{comment?.area}</td>
                                                                    <td>{comment?.roomtype}</td>
                                                                    <td>{comment?.aboutRoom?.length > 16? comment?.aboutRoom.substring(0, 15)+"." : comment?.aboutRoom }</td>

                                                                    



                                                                    <td>{comment?.Price}</td> */}
                                  <td className="actionicon1">
                                    <div className="d-flex">
                                      {/* <a
                                                                               
                                                                                className="btn btn-primary shadow btn-xs sharp mr-1" onClick={(e) => { roomDetailsView("show", comment?.id) }}
                                                                            >
                                                                             <HiOutlineEye/> <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </a> */}

                                      <a
                                        href="#delete"
                                        data-toggle="modal"
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={(e) => {
                                          handleShow(comment?.id);
                                        }}
                                      >
                                        {/*   <MdDelete />  */}{" "}
                                        <i className="fa fa-trash"></i>
                                      </a>

                                      <a
                                      href="#edit"
                                      className="btn btn-danger shadow btn-xs sharp"
                                      onClick={(e) => {
                                      handleeditShow(comment);
                                     }}
                                    >
                                    <EditOutlined />
                                    </a>
                                      
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div
                                            className="dataTables_info category"
                                            id="example3_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing {itemOffset} to {comments?.length < itemOffset + itemsPerPage ? comments?.length : itemOffset + itemsPerPage} of{" "}
                                        
                                            {comments?.length} entries
                                        </div>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next >"
                                            className="dataTables_paginate paging_simple_numbers category"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< previous"
                                            renderOnZeroPageCount={null}
                                        /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={comments[photoIndex].image}
          nextSrc={comments[(photoIndex + 1) % comments.length]}
          prevSrc={
            comments[(photoIndex + comments.length - 1) % comments.length]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + comments.length - 1) % comments.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % comments.length)
          }
        />
      )}

      <div className="bs-example">
        <div id="category" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Create Category
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body" style={{ padding: "26px" }}>
                <div className="assignment_form loader-parent">
                  <form>
                    <input
                      type="file"
                      className="form-control input-default "
                      name="image"
                      ref={ref}
                      accept="image/*"
                      onChange={handleImage}
                    />

                    <div className="submitBtn">
                      <button
                        type="button"
                        onClick={(e) => {
                          sendMessagesss(e);
                        }}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <div
                    className="loader-bx lodding "
                    style={{ display: "none" }}
                  >
                    <img src="img/lodder.gif" alt="loader" className="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="delete" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Are you sure want to delete Image
                </h5>
                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendledelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="edit54" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Are you sure want to edit Image
                </h5>
                <div className="deditBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={handleeditsubmit}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="editDiv"
        style={{
          display: "none",
          border: "0.4px solid white",
          borderRadius: "10px",
          boxShadow: "1px 1px 5px 1px #888888",
          margin: "30px",
        }}
      >
        <div className="roomadd">
          <div className="roomaddBack">
            <h3>Add Menu</h3>
            <div
              className="roomaddBackB"
              onClick={(e) => {
                editUser("cencel");
              }}
            >
              <GrClose />{" "}
            </div>
          </div>

          <div className="row">
            {/* <div className="col-12 col-sm-6">
                            <input

                                type="file"
                                className="form-control input-default "
                                name="image"
                                ref={ref}
                                accept="image/*"
                                onChange={
                                    handleImage}
                            />
                        </div> */}
            {/* <div className="col-12 col-sm-6">
                            <input name="gueste" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Gueste Count" />
                        </div> */}

            <div className="col-12 col-sm-6">
              <input
                name="name"
                type="text"
                onChange={(e) => {
                  hendlesetRoomDetailsAdd(e);
                }}
                placeholder="Enter Name"
              />
            </div>
            <div className="col-12 col-sm-6 categorySelect">
            <select onChange={(e) => setRoomDetails({ ...RoomDetails, category: e.target.value })}>
  <option value="">Select a category</option>
  {categories.map((category) => (
    <option key={category.id} value={category.title}>
      {category.title}
    </option>
  ))}
</select>

            </div>

            <div className="col-12 col-sm-6">
              <input
                name="description"
                type="text"
                onChange={(e) => {
                  hendlesetRoomDetailsAdd(e);
                }}
                placeholder="Enter Description"
              />
            </div>

            <div className="col-12 col-sm-6">
              <input
                name="price"
                type="number"
                onChange={(e) => {
                  hendlesetRoomDetailsAdd(e);
                }}
                placeholder="Enter Price"
              />
            </div>

            {/* <div className="col-12 col-sm-6" >
                            <input name="area" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Area in sq" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="roomtype" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Room Type" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="aboutRoom" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter About Room" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="price" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Price" />
                        </div> */}

            <div className="col-12 col-sm-12">
              <a
                className="roomAdd"
                onClick={(e) => {
                  sendMessagesss(e);
                }}
              >
                Submit
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        id="roomView"
        style={{
          display: "none",
          border: "0.4px solid white",
          borderRadius: "10px",
          boxShadow: "1px 1px 5px 1px #888888",
          margin: "30px",
        }}
      >
        <div className="roomadd">
          <div className="roomaddBack">
            <h3> Menu</h3>
            <div
              className="roomaddBackB"
              onClick={(e) => {
                roomDetailsView("cencel");
              }}
            >
              <GrClose />{" "}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              Gueste : {roomDetailsAbout?.gueste}
            </div>
            <div className="col-12 col-md-6">
              Area : {roomDetailsAbout?.area}
            </div>
            <div className="col-12 col-md-6">
              Room Type : {roomDetailsAbout?.roomtype}
            </div>
            <div className="col-12 col-md-6">
              Price : {roomDetailsAbout?.Price}
            </div>
            <div className="col-12">
              <div className="roomDImg">
                <img src={roomDetailsAbout?.image} alt="" />
              </div>
            </div>
            <div className="col-12">
              About Room : {roomDetailsAbout?.aboutRoom}
            </div>
          </div>

          {/* <h1>Proceesing</h1> */}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <div>
          <h5 className="modal-title" style={{ padding: "8px 27px" }}>
            Are you sure want to delete Room
          </h5>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={hendledelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editmoelOpen} onHide={handleeditClose}>
        <Modal.Header closeButton>
          {/* Add modal header content if needed */}
        </Modal.Header>
        <div>
          <h5 className="modal-title" style={{ padding: "8px 27px" }}>
            Are you sure want to edit Category1
          </h5>
      
   
          <div className="row editfield">
          <div className="col-12 col-sm-6">
            <input
              name="name"
              type="text"
              value={selectedComment?.name}
              onChange={(e) => {
                hendlesetedit(e);
              }}
              placeholder="Enter name"
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              name="price"
              type="text"
              value={selectedComment?.price}
              onChange={(e) => {
                hendlesetedit(e);
              }}
              placeholder="Enter name"
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              name="category"
              type="text"
              value={selectedComment?.category}
              onChange={(e) => {
                hendlesetedit(e);
              }}
              placeholder="Enter category"
            />
          </div>

          <div className="col-12 col-sm-6">
            <input
              name="description"
              type="text"
              value={selectedComment?.description}
              onChange={(e) => {
                hendlesetedit(e);
              }}
              placeholder="Enter description"
            />
          </div>
          
          </div>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditsubmit}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleeditClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Menu;
