import React from "react";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import Footer from "../Footer/Footer";
import Gallery2 from "../Gallery/Gallery2";
import Header from "../Header/Header";
import About from "./About";
import Banner from "./Banner";
import Events from "./Events";
import HomeRoom from "./HomeRoom";
import Location from "./Location";
import Menu from "./Menu";
import Photos from "./Photos";
import Reservations from "./Reservations";
import Testimonial from "./Testimonial";

function Home() {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Banner />
      <About />
      <HomeRoom />
      {/* <Photos/> */}
      <Menu />
      <Events />
      <Location />
      <Gallery2 />
      <Testimonial />
      <Reservations />
      <Footer />
    </div>
  );
}

export default Home;
