import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Header from "../Header/Header";
import "./BookRoom.css";
import "react-phone-input-2/lib/style.css";
import { auth, db, firebase } from "../../Firebase";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

function BookRoom() {
  const [mynumber, setmynumber] = useState("");
  const [otp, setOtp] = useState("");
  const [final, setfinal] = useState("");
  const [roomDetails, setroomDetails] = useState("");
  const [review, setReview] = useState({
    name: "",
    email: "",
    review: "",
  });

  console.log("sadsad");

  const id = useLocation();
  const [details, setDetails] = useState({ Name: "" });
  const [person, setperson] = useState("1");
  const [childrens, setchildrens] = useState("0");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  useEffect(() => {
    db.collection("roomDetails")
      .doc(id?.state?.roomId)
      .get()
      .then((snapshot) => {
        setroomDetails(snapshot?.data());
      });
  }, []);

  const loginPop = (e) => {
    if (e == "show") {
      document.getElementById("id01").style.display = "block";
    } else {
      document.getElementById("id01").style.display = "none";
    }
  };

  const userMainPage = async (e) => {
    // e.preventdefault()
    const dones = await db.collection("userEnquiry").add({
      name: details?.Name,
      checkInDate: startDate,
      checkOutDate: endDate,
      person: person,
      childrens: childrens,
      roomType: roomDetails?.roomtype,
      phoneNumber: "+" + mynumber,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    document.getElementById("id01").style.display = "none";
    toast.success("Your Enquiry Submited");
  };

  const userReviews = async (e) => {
    // e.preventdefault()

    console.log("Ter");

    if (review?.name == "" || review?.email == "" || review?.review == "") {
      toast.success("All field requird");
    } else {
      console.log("T33er");

      const dones = await db.collection("userReview").add({
        name: review?.name,
        email: review?.email,
        reviews: review?.review,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setReview({ email: "", name: "", review: "" });
      // document.getElementById('id01').style.display = 'none'
      toast.success("Thank you for Review !");
    }
  };

  const hendledetailsAdd = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const hendleReviewAdd = (e) => {
    const { name, value } = e.target;
    setReview({ ...review, [name]: value });
  };

  return (
    <div>
      <div className="knsl-app">
        <ScrollToTop />

        <Header />

        <section className="knsl-banner-simple cpm-180 knsl-transition-bottom">
          <img src="img/palm.svg" className="knsl-deco-left" alt="palm" />
          <img src="img/palm.svg" className="knsl-deco-right" alt="palm" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="knsl-center knsl-title-frame">
                  <h1 className="knsl-mb-20 knsl-h1-inner">Book Room</h1>
                  <ul className="knsl-breadcrumbs">
                    <li>
                      <Link to={"/"}>Home</Link>
                      {/* <a href="index.html"></a> */}
                    </li>
                    {/* <li><a href="#">Rooms</a></li> */}
                    <li>
                      <span>{roomDetails?.roomtype}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="knsl-p-100-100">
          <div className="container">
            <div className="row" container>
              <div className="col-lg-8">
                {/* <!-- description --> */}
                <section className="knsl-mb-60 knsl-scroll-animation">
                  <h3 className="knsl-mb-40">
                    Hotel SD Prime is waiting for you!
                  </h3>
                  <p>
                    SD Prime is committed to providing you best service in the
                    city. We offer you a wide range of hotels with budgeted
                    rates and all amenities at your destination. We have been in
                    the hospitality industry for over a decade and are known for
                    our dedication to customer service, convenience, and
                    promptness. Our goal is to provide you with a memorable
                    experience when traveling, so you can start exploring Jaipur
                    right away.SD Prime has serviced more than 5000+ customers
                    worldwide, and we have established a good reputation through
                    our hard work and commitment to customer service. One of our
                    fundamental values is ensuring that your visit to Jaipur
                    will be seamless and leave you with beautiful memories. When
                    it comes to meeting your needs, we'll find a perfect hotel
                    for you, whether it's 5-star luxury or family-friendly
                    accommodation. Whether you're traveling with friends or
                    family, we'll make sure everyone feels comfortable and
                    welcome at any hotel in the SD Prime portfolio.
                  </p>
                </section>
                {/* <!-- description end -->

            <!-- map --> */}
                <section>
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="knsl-mb-40 knsl-scroll-animation">
                        Location
                      </h3>
                    </div>
                    <div className="col-lg-12">
                      <div className="knsl-map-frame knsl-mb-60">
                        {/* <div className="knsl-lock"><i className="fas fa-lock"></i></div>
                                                <div id="map" className="knsl-map"></div> */}
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3559.5437424279307!2d75.755008!3d26.8544605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db55bb28a316f%3A0x69a17430d1b2c86c!2sHOTEL%20SD%20PRIME!5e0!3m2!1sen!2sin!4v1651130456919!5m2!1sen!2sin"
                          width="100%"
                          height="450"
                          style={{ border: "0" }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </section>
                {/* <!-- map end --> */}

                <section>
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="knsl-mb-40 knsl-scroll-animation">
                        Leave a review
                      </h3>
                    </div>
                    <div className="col-lg-12">
                      <form className="knsl-scroll-animation">
                        <div className="row">
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Name"
                              value={review?.name}
                              name="name"
                              onChange={(e) => {
                                hendleReviewAdd(e);
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              value={review?.email}
                              name="email"
                              onChange={(e) => {
                                hendleReviewAdd(e);
                              }}
                            />
                          </div>
                          <div className="col-lg-12">
                            <textarea
                              rows="8"
                              placeholder="Text"
                              name="review"
                              value={review?.review}
                              onChange={(e) => {
                                hendleReviewAdd(e);
                              }}
                            ></textarea>
                          </div>
                          <div className="col-lg-12">
                            <div className="knsl-form-submit-frame">
                              {/* <!-- temporary button to show the popup --> */}
                              <a
                                className="knsl-btn"
                                onClick={(e) => {
                                  userReviews(e);
                                }} /*  data-fancybox href="#knsl-success" */
                              >
                                Send
                              </a>
                              {/* <!-- <button className="knsl-btn">Send</button> --> */}
                              <div className="knsl-text-sm">
                                *We promise not to disclose your personal
                                information to third parties.
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4">
                {/* <!-- sidebar --> */}
                <div
                  className="knsl-sticky knsl-stycky-right"
                  data-margin-top="100"
                >
                  {/* <!-- room info --> */}
                  <div className="knsl-room-features-lg knsl-mb-20">
                    <div className="knsl-feature">
                      <div className="knsl-icon-frame">
                        <img src="img/icons/bed.svg" alt="icon" />
                      </div>
                      <span>{roomDetails?.gueste} Guests</span>
                    </div>
                    <div className="knsl-feature">
                      <div className="knsl-icon-frame">
                        <img src="img/icons/square.svg" alt="icon" />
                      </div>
                      <span>{roomDetails?.area} Ft²</span>
                    </div>
                  </div>
                  {/* <!-- room info end --> */}

                  <div className="knsl-price-card knsl-mb-20">
                    <div className="knsl-price-number">
                      ₹{roomDetails?.Price}
                      <span>/night</span>
                    </div>
                    {/* <div className="knsl-btn" onClick={(e) => {
                                            loginPop("show")
                                        }}
                                            style={{ width: "auto" }}>Reservation</div> */}
                  </div>

                  <div id="id01" className="modal">
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6"></div>
                    </div>

                    <form className="modal-content animate">
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                          <div className="container">
                            <div className="imgcontainer">
                              <img
                                src="img/png.png"
                                alt="Avatar"
                                className=""
                              />
                            </div>
                            <div className="login_text">
                              <h3>Not a member? Join Now</h3>
                              <p>
                                Enjoy our lowest rates, all the time
                                <br />
                                Free in-room Wi-Fi
                                <br />
                                Mobile check-in and more
                              </p>
                              <button
                                style={{ width: "40%", fontSize: "20px" }}
                              >
                                Join Now
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-5">
                          <h4 className="login_page">
                            {" "}
                            {/* Sign in to */} Your Details {/* account */}{" "}
                          </h4>

                          <div className="container">
                            <div id="sign-in-button"></div>
                            <label for="psw">
                              <b>Name</b>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Your Name"
                              name="Name"
                              onChange={(e) => {
                                hendledetailsAdd(e);
                              }}
                            />

                            <label for="uname">
                              <b>Number</b>
                            </label>
                            {/* <input className="form-control" type="text" placeholder="Enter Number" name="number" required /> */}
                            <PhoneInput
                              country={"in"}
                              value={mynumber}
                              onChange={setmynumber}
                            />

                            {/* <div  onClick={(e) => signin(e)}>Login</div> */}

                            <div
                              className={
                                details?.Name?.length < 1
                                  ? "enq disBtn  "
                                  : mynumber?.length < 5
                                  ? "enq disBtn"
                                  : "enq"
                              }
                              /*  type="submit" */ onClick={(e) => {
                                userMainPage(e);
                              }}
                            >
                              Reservation
                            </div>
                            {/* <label style={{ display: "flex" }}>
                                                            <input className="" type="checkbox" checked="checked" name="remember" style={{ marginTop: "5px" }} />
                                                            <p style={{ paddingLeft: "6px" }}>Remember me</p>
                                                        </label> */}
                            {/* <span className="psw">Forgot <a href="#">password?</a></span> */}
                          </div>
                        </div>
                        <div className="col-md-1">
                          <span
                            className="close"
                            onClick={(e) => {
                              loginPop("close");
                            }}
                            title="Close Modal"
                          >
                            &times;
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="knsl-book-form">
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="knsl-input-frame">
                            <label for="check-in">Check in</label>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              // showTimeSelect
                              // timeFormat="HH:mm:ss"
                              className="form-control"
                              minDate={new Date()}
                              placeholderText="Date & Time"
                              // dateFormat="yyyy-MM-dd"
                            />
                            {/* <input id="check-in" type="text" className="datepicker-here" data-position="bottom left"
                                                            placeholder="Select date" autocomplete="off" readonly="readonly" /> */}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="knsl-input-frame">
                            <label for="check-out" className="knsl-add-icon">
                              Check out
                            </label>
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setendDate(date)}
                              // showTimeSelect
                              // timeFormat="HH:mm:ss"
                              className="form-control"
                              minDate={new Date()}
                              placeholderText="Date & Time"
                              // dateFormat="yyyy-MM-dd"
                            />
                            {/* <input id="check-out" type="text" className="datepicker-here" data-position="bottom left"
                                                            placeholder="Select date" autocomplete="off" readonly="readonly" /> */}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="knsl-select-frame">
                            <label for="person">Person</label>
                            <select id="person" style={{ display: "none" }}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4" disabled>
                                4
                              </option>
                            </select>
                            <div className="nice-select" tabIndex="0">
                              <span className="current">1</span>
                              <ul className="list">
                                <li
                                  data-value="1"
                                  onClick={(e) => {
                                    setperson("1");
                                  }}
                                  className="option selected focus"
                                >
                                  1
                                </li>
                                <li
                                  data-value="2"
                                  onClick={(e) => {
                                    setperson("2");
                                  }}
                                  className="option"
                                >
                                  2
                                </li>
                                <li
                                  data-value="3"
                                  onClick={(e) => {
                                    setperson("3");
                                  }}
                                  className="option"
                                >
                                  3
                                </li>
                                <li
                                  data-value="4"
                                  onClick={(e) => {
                                    setperson("4");
                                  }}
                                  className="option disabled"
                                >
                                  4
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="knsl-select-frame">
                            <label for="children">Childrens</label>
                            <select id="children" style={{ display: "none" }}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4" disabled>
                                4
                              </option>
                            </select>
                            <div className="nice-select" tabIndex="0">
                              <span className="current">0</span>
                              <ul className="list">
                                <li
                                  data-value="1"
                                  onClick={(e) => {
                                    setchildrens("1");
                                  }}
                                  className="option selected"
                                >
                                  1
                                </li>
                                <li
                                  data-value="2"
                                  className="option"
                                  onClick={(e) => {
                                    setchildrens("2");
                                  }}
                                >
                                  2
                                </li>
                                <li
                                  data-value="3"
                                  className="option"
                                  onClick={(e) => {
                                    setchildrens("3");
                                  }}
                                >
                                  3
                                </li>
                                <li
                                  data-value="4"
                                  className="option disabled"
                                  onClick={(e) => {
                                    setchildrens("4");
                                  }}
                                >
                                  4
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 knsl-center">
                          <div
                            className={
                              startDate == null
                                ? "knsl-btn disBtn "
                                : endDate == null
                                ? "knsl-btn disBtn "
                                : "knsl-btn"
                            }
                            onClick={(e) => {
                              loginPop("show");
                            }}
                            style={{ width: "auto" }}
                          >
                            Reservation
                          </div>
                          {/* <button type="submit" className="knsl-btn"><img src="img/icons/search.svg" className="knsl-zoom"
                                                        alt="icon" />Serch room</button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BookRoom;
