import logo from './logo.svg';
import './App.css';
import Header from './Component/Header/Header';
import Footer from './Component/Footer/Footer';
import Home from './Component/Home/Home';
import Reservation from './Component/Reservation/Reservation';
import Testimonial from './Component/Home/Testimonial';
import Events from './Component/Events/Events';
import Abouts from './Component/About/Abouts';
import Contact from './Component/Contact/Contact';
import AppRoutes from './Routes/Routes';

function App() {
  return (
    <div>
 

<AppRoutes/>

      {/* <Home/> */}
   
 
    </div>
  );
}

export default App;
