import React from "react";

function Location() {
  return (
    <div>
      <section className="section contact-section bg-fade" id="next">
        <div className="container">
          <h1 className="contact-heading">Contact Us</h1>
          <div className="row custom-contact-row">
            <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d113880.53894018526!2d75.6769136!3d26.8791479!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5292a82a0f3%3A0x7473b1abe286a706!2sCollection%20O%2077414%20Hotel%20Krishna%20Pride!5e0!3m2!1sen!2sin!4v1653137345823!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-md-5" data-aos="fade-up" data-aos-delay="200">
              <div className="row">
                <div className="col-md-10 ml-auto contact-info">
                  <p>
                    <span className="d-block"><img src="images/9.svg"/>Address:</span>{" "}
                    <span className="text-black">
                      {" "}
                      Nivik hospital, Udb Indus, Opposite Nikki road lines , 29
                      uday nagar b, Jaipur, Rajasthan 302020
                    </span>
                  </p>
                  <p>
                    <span className="d-block"><img src="images/10.svg"/>Phone:</span>
                    <span className="text-black">+918005501767</span>
                    <br></br>
                    <span className="text-black">+919928992889</span>
                  </p>
                  <p>
                    <span className="d-block"><img src="images/11.svg"/>Email:</span>{" "}
                    <span className="text-black">
                      {" "}
                      <a
                        href="mailto:Hotelkrishnapride@gmail.com"
                        className="__cf_email__ emailSize"
                      >
                        Hotelkrishnapride@gmail.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Location;
