import React, { useEffect, useState } from "react";
import { db } from "../../Firebase";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

function Events() {
  const [comments, setComments] = useState([]);
  const [filterByC, setfilterByC] = useState("All");

  useEffect(() => {
    const unsub = db
      .collection("events")
      .orderBy("createdAt", "desc")
      .limit(3)
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });
        setComments(oderdetails);
      });

    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      <section className="section blog-post-entry bg-light">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-7">
              <h2 className="heading" data-aos="fade-up">
                Events
              </h2>
              <p data-aos="fade-up">
                Krishna Pride is not just about luxury and comfort, though. The
                hotel is committed to sustainability and eco-friendliness. The
                hotel has implemented various measures to reduce its
                environmental impact, including energy-efficient lighting, waste
                reduction, and water conservation initiatives.
              </p>
            </div>
          </div>
          <div className="row">
            {comments &&
              comments?.map((data, i) => {
                const createDate = dateFormat(
                  data?.createdAt?.toDate(),
                  "mmmm dd, yyyy"
                );

                return (
                  i < 3 && (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 post"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      key={i}
                    >
                      <div className="media media-custom d-block mb-4 h-100">
                        <a href="#" className="mb-4 d-block eventImg">
                          <img
                            src={data?.image}
                            alt="Image placeholder"
                            className="img-fluid"
                          />
                        </a>
                        <div className="media-body">
                          <span className="meta-post">{createDate}</span>
                          <h2 className="mt-0 mb-3">
                            <a href="#">{data?.title}</a>
                          </h2>
                          <p>{data?.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}

            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12 post" data-aos="fade-up" data-aos-delay="100">
              <div className="media media-custom d-block mb-4 h-100">
                <a href="#" className="mb-4 d-block"><img src="image/events/11.png"
                  alt="Image placeholder" className="img-fluid" /></a>
                <div className="media-body">
                  <span className="meta-post">February 26, 2018</span>
                  <h2 className="mt-0 mb-3"><a href="#">Travel Hacks to Make Your Flight More Comfortable</a></h2>
                  <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                    blind texts.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 post" data-aos="fade-up" data-aos-delay="200">
              <div className="media media-custom d-block mb-4 h-100">
                <a href="#" className="mb-4 d-block"><img src="image/events/22.png"
                  alt="Image placeholder" className="img-fluid" /></a>
                <div className="media-body">
                  <span className="meta-post">February 26, 2018</span>
                  <h2 className="mt-0 mb-3"><a href="#">5 Job Types That Aallow You To Earn As You Travel The World</a></h2>
                  <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 post" data-aos="fade-up" data-aos-delay="300">
              <div className="media media-custom d-block mb-4 h-100">
                <a href="#" className="mb-4 d-block"><img src="image/events/33.png"
                  alt="Image placeholder" className="img-fluid" /></a>
                <div className="media-body">
                  <span className="meta-post">February 26, 2018</span>
                  <h2 className="mt-0 mb-3"><a href="#">30 Great Ideas On Gifts For Travelers</a></h2>
                  <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. t is a
                    paradisematic country, in which roasted parts of sentences.</p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row justify-content-center">
            <Link
              to={"/events"}
              className="btn btn-primary text-white py-2 mr-3 evtBtn"
            >
              More
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Events;
