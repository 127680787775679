import firebase from 'firebase';
// import env from "react-dotenv";
  
const firebaseConfig = {
  apiKey: "AIzaSyD-ihPFCK4JqVgGdgCd8cVozDXVOM_QEnI",
  authDomain: "krishnapride-4c0bd.firebaseapp.com",
  projectId: "krishnapride-4c0bd",
  storageBucket: "krishnapride-4c0bd.appspot.com",
  messagingSenderId: "975864487683",
  appId: "1:975864487683:web:27b1225c9ab21238365336",
  measurementId: "G-QD6W0KXJH5"
};
  
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
const db = firebase.firestore()
const storage = firebase.storage()
const devanalytics = firebase.analytics();
export {auth , firebase, db,storage, devanalytics};